import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { GriefControllerService } from '../../../core/api/client/services/grief-controller.service';
import { map, tap } from 'rxjs/operators';
import { DossierTable } from '../../../models/dossier-table.interface';
import { StrictHttpResponse } from '../../../core/api/client/strict-http-response';
import { DossierDTO, PageResultDossierDTO, GriefWebRecherche } from '../../../core/api/client/models';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GrievancesService {
    public montantTotal: any = 0;

    constructor(
        public _griefControllerService: GriefControllerService,
        public _messageService: MessageService,
    ) { }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getAllGrievances(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<PageResultDossierDTO>> {
        return this._griefControllerService.listeTousUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<PageResultDossierDTO>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getAllGrievancesList(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeTousLegerUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    public getAllGrievancesListNewIncluded(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeTousLegerNouveauxInclusUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getActiveGrievances(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<PageResultDossierDTO>> {
        return this._griefControllerService.listeActifsUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<PageResultDossierDTO>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getActiveGrievancesList(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeActifsLegerUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public async getArbitratorGrievances(numeroPage: number, date: any): Promise<any> {
        return await this._griefControllerService.listeDossierEnArbitragesUsingGET({ numeroPage: numeroPage, date: date }).pipe(
            map((pageResultDossier: any) => {
                if (pageResultDossier.content) {
                    pageResultDossier.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getArbitratorGrievancesExcel(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeArbitragesUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getNewGrievances(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<PageResultDossierDTO>> {
        return this._griefControllerService.listeNouveauxUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<PageResultDossierDTO>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getNewGrievancesList(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeNouveauxLegerUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getOrganizationalGrievances(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<PageResultDossierDTO>> {
        return this._griefControllerService.listeOrganisationnelsUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<PageResultDossierDTO>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getOrganizationalGrievancesList(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listeOrganisationnelsLegerUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getPersonalGrievances(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<PageResultDossierDTO>> {
        return this._griefControllerService.listePersonnelsUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<PageResultDossierDTO>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    sessionStorage.setItem('excelService', JSON.stringify(false));
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {number} numeroPage
     * @returns Promise
     */
    public getPersonalGrievancesList(numeroPage: number, rechercheDossierLegerDto: any): Promise<HttpResponse<any>> {
        return this._griefControllerService.listePersonnelsLegerUsingPOSTResponse({ numeroPage, rechercheDossierLegerDto }).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {any} data
     * @returns any
     */
    public getGrievancesListStorage(data: any): any {
        data?.body?.content.map((res: any) => {
            return this._buildDossier(res);
        });
        return data;
    }

    /**
     * @param  {GriefWebRecherche} casWebRecherche
     * @returns Promise
     */
    public getGrievancesRecherche(casWebRecherche: GriefWebRecherche): Promise<HttpResponse<any>> {
        return this._griefControllerService.rechercheLegerUsingPOSTResponse(casWebRecherche).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {GriefWebRecherche} casWebRecherche
     * @returns Promise
     */
    public getRechercheDossiers(casWebRecherche: GriefWebRecherche): Promise<HttpResponse<any>> {
        return this._griefControllerService.rechercheUsingPOSTResponse(casWebRecherche).pipe(
            map((pageResultDossier: StrictHttpResponse<any>) => {
                if (pageResultDossier.body && pageResultDossier.body.content) {
                    pageResultDossier.body.content.map((dossier: DossierDTO) => {
                        return this._buildDossier(dossier);
                    });
                }
                return pageResultDossier;
            })
        ).toPromise();
    }

    /**
     * @param  {Array<string>} ids
     * @returns Promise
     */
    public deleteGrief(ids: Array<string>): Promise<StrictHttpResponse<null>> | unknown {
        return this._griefControllerService.approuverUsingPOSTResponse(ids).pipe(
            tap(() => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Gestion des griefs',
                    detail: 'Votre demande de suppression de grief à été effectuée avec succès'
                });
            })
        ).toPromise().catch((err: any) => {
            console.log(err);
            this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est servenue' });
        });
    }

    /**
     * @param  {DossierDTO} dossier
     * @returns DossierTable
     */
    _buildDossier(dossier: DossierDTO): DossierTable {
        const dossierTable: DossierTable = dossier;
        dossierTable.dateDepot = this._buildDateDepot(dossierTable.dateDepot);
        dossierTable.numeroGriefLabel = dossierTable.numeroGrief;
        dossierTable.localSyndicalLabel = (dossierTable.localSyndical?.valeurTexte) ? dossierTable.localSyndical?.valeurTexte : dossierTable.localSyndical;
        dossierTable.regionSyndicaleLabel = (dossierTable.regionSyndicale?.valeurTexte) ? dossierTable.regionSyndicale?.valeurTexte : dossierTable.regionSyndical;
        dossierTable.typeLabel = this._typeGrief(dossierTable.type);
        dossierTable.motifLabel = (dossierTable.motif?.valeurTexte) ? dossierTable.motif?.valeurTexte : dossierTable.motif;
        dossierTable.sousMotifLabel = (dossierTable.sousMotif?.valeurTexte) ? dossierTable.sousMotif?.valeurTexte : dossierTable.sousMotif;
        dossierTable.requerantLabel = (dossierTable.coordonneeRequerantList?.[0]?.nomComplet)
            ? dossierTable.coordonneeRequerantList?.[0]?.nomComplet
            : dossierTable.requerant;
        dossierTable.noDivisionRequeranteLabel = dossierTable.coordonneeRequerantList?.[0]?.matricule;
        dossierTable.posteDuRequerant = dossierTable.coordonneeRequerantList?.[0]?.poste;
        dossierTable.noUniteStructurellePatronaleLabel = dossierTable.noUniteStructurelleRequerante;
        dossierTable.designationUniteStructurelleRequeranteLabel = (dossierTable.type === 'PAT') ?
            dossier.nomUniteStructurelleRequerante :
            dossierTable.coordonneeRequerantList?.[0]?.nomUnite;
        dossierTable.nomReponsableRHLabel = dossierTable.responsableRH?.utilisateur?.prenomNom;
        dossierTable.superieurLabel = ((dossierTable.type === 'IND' || dossierTable.type === 'COL') && dossierTable?.coordonneePatronalList) ?
            dossierTable?.coordonneePatronalList[0]?.nomComplet : '';
        dossierTable.gestionnaire2Label = ((dossierTable.type === 'IND' || dossierTable.type === 'COL') && dossierTable?.coordonneePatronalList) ?
            dossierTable?.coordonneePatronalList[1]?.nomComplet : '';
        dossierTable.gestionnaire3Label = ((dossierTable.type === 'IND' || dossierTable.type === 'COL') && dossierTable?.coordonneePatronalList) ?
            dossierTable?.coordonneePatronalList[2]?.nomComplet : '';
        dossierTable.penaliteLabel = (dossierTable.montantReglement) ?
            parseFloat(dossierTable.montantReglement).toFixed(2).toLocaleString() + '$' :
            '';
        dossierTable.comiteGriefLabel = this._foundComiteGriefLabel(dossierTable?.actionAnalyseList);
        dossierTable.procureurPatronalLabel = dossierTable.procureurPatronal?.valeurTexte;
        dossierTable.actionArbitrageLabel = dossierTable.arbitre?.valeurTexte;
        dossierTable.totalDesDepensesLabel = (dossierTable.depenseArbitrageList) ? this._totalDepenses(dossierTable.depenseArbitrageList) : '';
        dossierTable.statutLabel = dossierTable.statut;
        dossierTable.descriptionLabel = dossierTable.descriptionGrief;
        dossierTable.commentaireLabel = dossierTable.commentaire;

        return dossierTable;
    }

    /**
     * @param  {any} date
     * @returns string
     */
    _buildDateDepot(date: any): string {
        if (date) {
            return formatDate(date, 'yyyy-MM-dd', 'en-US');
        } else {
            return '';
        }
    }

    /**
     * @param  {any} type
     * @returns string
     */
    _typeGrief(type: any): string {
        let ret;
        switch (type) {
            case 'IND':
                ret = 'Individuel';
                break;
            case 'COL':
                ret = 'Collectif';
                break;
            case 'PAT':
                ret = 'Patronal';
                break;
            case 'GEN':
                ret = 'Portée générale';
                break;
            default:
                ret = '';
                break;
        }

        return ret;
    }

    _foundComiteGriefLabel(actionArbitrage: any): any {
        if (actionArbitrage !== null || actionArbitrage !== undefined) {
            for (let i = 0; i <= actionArbitrage?.length; i++) {
                if (actionArbitrage[i]?.designationEtape === 'Comité de griefs tenu') {
                    return actionArbitrage[i]?.dateActionCompletee?.split(' ')[0];
                }
            }
        } else {
            return '';
        }
    }

    /**
     * @param  {any} depense
     * @returns any
     */
    _totalDepenses(depense: any): any {
        this.montantTotal = 0;

        if (!depense[0]?.montantFacture) {
            return '';
        } else {
            for (let i = 0; i <= depense?.length; i++) {
                if (depense[i] !== undefined && depense[i].montantFacture) {
                    const montant = parseFloat(depense[i]?.montantFacture);
                    this.montantTotal += montant;
                }
            }
            return this.montantTotal.toLocaleString() + '$';
        }
    }
}
