import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HqSideBarComponent } from '@ngxhq/theme-ngxhq';
import { Observable } from 'rxjs';
import { InformationDTO } from '../../core/api/client/models/information-dto';
import { InfoService } from '../../modules/grievances/services/info.service';
const pjson = require('../../../../package.json');

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
})
export class MainMenuComponent {
    public env: string = pjson.environment;

    public swaggerLink: string = 'https://isg-backend-dev.apps.c1.sc.hydro.qc.ca/swagger-ui.html';

    @Output() expandedChange = new EventEmitter<any>();
    @ViewChild(HqSideBarComponent, { static: true })

    private sideBar: HqSideBarComponent;
    href!: string;
    public infoApp$: Observable<InformationDTO | null> = this._infoAppService.infoApp$;

    constructor(
        private router: Router,
        private _infoAppService: InfoService,
    ) { }

    @Input()
    set expanded(value: any) {
        if (this.sideBar && this.sideBar.expanded !== value) {
            this.sideBar.expanded = value;
        }
        this.expandedChange.emit(value);
        this.href = this.router.url.split('/')[1];
    }

    async doInit() {
    }

    public swaggerExternalLink(): void {
        window.open('https://isg-backend-dev.apps.c1.sc.hydro.qc.ca/swagger-ui.html', '_blank');
    }
}
