import { APP_BASE_HREF } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';

@Component({
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.scss']
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class GlobalErrorComponent extends ComponentBase implements DoInit {
    /* eslint-disable */
    public httpCodes = {
        401: 'Non autorisé',
        403: 'Accès refusé',
        404: 'Introuvable',
        500: 'Erreur interne du serveur',
        502: 'Passerelle incorrecte',
        503: 'Service indisponible',
        504: 'Temps d\'attente expiré'
    };
    /* eslint-enable */

    public httpCode: string | null;

    constructor(@Inject(APP_BASE_HREF) public href: string, private route: ActivatedRoute) {
        super();
    }

    doInit(): void {
        if (this.route.snapshot && this.route.snapshot.paramMap) {
            this.httpCode = this.route.snapshot.paramMap.get('httpCode');
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState() {
        window.location.reload();
    }
}
