import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InformationDTO } from '../../../core/api/client/models/information-dto';
import { InfoService as InfoController } from '../../../core/api/client/services/info.service';

@Injectable({
    providedIn: 'root'
})
export class InfoService {
    private _infoApp$: BehaviorSubject<InformationDTO | null> = new BehaviorSubject<InformationDTO | null>(null);
    constructor(private _infoAppController: InfoController) { }

    /**
     * @returns Promise
     */
    public getInfoApp(): Promise<InformationDTO> {
        return this._infoAppController.getInformationUsingGET().pipe(
            tap((infoApp: InformationDTO) => this._infoApp$.next((infoApp)))
        ).toPromise();
    }

    /**
     * @returns BehaviorSubject
     */
    public get infoApp$(): BehaviorSubject<InformationDTO | null> {
        return this._infoApp$;
    }

}
