/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UniteStructurelleDTO } from '../models/unite-structurelle-dto';
import { UtilisateurDTO } from '../models/utilisateur-dto';

/**
 * Liste Controller
 */
@Injectable({
  providedIn: 'root',
})
class ListeService extends __BaseService {
  static readonly findByNoUniteStructurelleUsingGETPath = '/api/v1/liste/rechercheNoUniteStructurelle';
  static readonly findByNomUsingGETPath = '/api/v1/liste/rechercheParNom';
  static readonly findByNomPrenomGeneralUsingGETPath = '/api/v1/liste/rechercheParNomEtPrenomGraph';
  static readonly findByNomGeneralUsingGETPath = '/api/v1/liste/rechercheParNomGraph';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste des NoUniteStructurelle en local
   * @param noUniteStructurelle Le numero recherche
   * @return Requête traitée avec succès
   */
  findByNoUniteStructurelleUsingGETResponse(noUniteStructurelle?: string): __Observable<__StrictHttpResponse<Array<UniteStructurelleDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (noUniteStructurelle != null) __params = __params.set('noUniteStructurelle', noUniteStructurelle.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/liste/rechercheNoUniteStructurelle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UniteStructurelleDTO>>;
      })
    );
  }
  /**
   * Liste des NoUniteStructurelle en local
   * @param noUniteStructurelle Le numero recherche
   * @return Requête traitée avec succès
   */
  findByNoUniteStructurelleUsingGET(noUniteStructurelle?: string): __Observable<Array<UniteStructurelleDTO>> {
    return this.findByNoUniteStructurelleUsingGETResponse(noUniteStructurelle).pipe(
      __map(_r => _r.body as Array<UniteStructurelleDTO>)
    );
  }

  /**
   * Liste des utilisateurs en local
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomUsingGETResponse(nom?: string): __Observable<__StrictHttpResponse<Array<UtilisateurDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (nom != null) __params = __params.set('nom', nom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/liste/rechercheParNom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UtilisateurDTO>>;
      })
    );
  }
  /**
   * Liste des utilisateurs en local
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomUsingGET(nom?: string): __Observable<Array<UtilisateurDTO>> {
    return this.findByNomUsingGETResponse(nom).pipe(
      __map(_r => _r.body as Array<UtilisateurDTO>)
    );
  }

  /**
   * Liste des utilisateurs par nom et prenom graph
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomPrenomGeneralUsingGETResponse(nom?: string): __Observable<__StrictHttpResponse<Array<UtilisateurDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (nom != null) __params = __params.set('nom', nom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/liste/rechercheParNomEtPrenomGraph`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UtilisateurDTO>>;
      })
    );
  }
  /**
   * Liste des utilisateurs par nom et prenom graph
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomPrenomGeneralUsingGET(nom?: string): __Observable<Array<UtilisateurDTO>> {
    return this.findByNomPrenomGeneralUsingGETResponse(nom).pipe(
      __map(_r => _r.body as Array<UtilisateurDTO>)
    );
  }

  /**
   * Liste des utilisateurs graph
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomGeneralUsingGETResponse(nom?: string): __Observable<__StrictHttpResponse<Array<UtilisateurDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (nom != null) __params = __params.set('nom', nom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/liste/rechercheParNomGraph`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UtilisateurDTO>>;
      })
    );
  }
  /**
   * Liste des utilisateurs graph
   * @param nom Le nom recherche
   * @return Requête traitée avec succès
   */
  findByNomGeneralUsingGET(nom?: string): __Observable<Array<UtilisateurDTO>> {
    return this.findByNomGeneralUsingGETResponse(nom).pipe(
      __map(_r => _r.body as Array<UtilisateurDTO>)
    );
  }
}

module ListeService {
}

export { ListeService }
