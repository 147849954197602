import { Injectable } from '@angular/core';
import { OpenIdService, AuthentificationState } from '@ngxhq/security';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    private _roles$: BehaviorSubject<string[] | undefined> = new BehaviorSubject<string[] | undefined>(undefined);

    constructor(
        private _openId: OpenIdService
    ) { }

    /**
     * @returns Observable
     */
    public getRoles(): Observable<string[] | undefined> {
        return this._roles$;
    }

    /**
     * temp pour Patrick, il faut l'enlèver àpres
     * @param  {string[]} roles
     * @returns void
     */
    public setRoles(roles: string[]): void {
        roles[0] = this._getRoleName(roles[0]);
        this._roles$.next(roles);
    }
    /**------------------------------------------- */

    /**
     * @returns Observable
     */
    public initRoles(): Observable<string[] | undefined> {
        return this._openId.status.pipe(
            switchMap((data: AuthentificationState) => this._getRoles(data.isAuthenticated)),
        );
    }

    /**
     * @param  {boolean} isAuthenticated
     * @returns Observable
     */
    private _getRoles(isAuthenticated: boolean): Observable<string[] | undefined> {
        return from((this._openId.getClaim('roles'))).pipe(
            map((data: string | undefined) => {
                let res: string[] | undefined = (isAuthenticated) ? (data as unknown) as string[] : undefined;
                if (isAuthenticated && !data) { res = []; }
                if (res?.includes(this._getRoleName('admin'))) {
                    res = ['isg.Admin'];
                }
                this._roles$.next(res);
                return res;
            })
        );
    }

    /**
     * @param  {string|string[]} appRoles
     * @returns Observable
     */
    public hasRole(appRoles: string | string[]): Observable<boolean> {
        return this._roles$.pipe(
            filter((roles: string[] | undefined) => roles !== undefined),
            map((roles: string[] | undefined) => {
                const claimRoles: string[] = (roles) ? roles : [];
                let hasRole: boolean;
                if (typeof appRoles === 'string') {
                    hasRole = claimRoles.includes(this._getRoleName(appRoles));
                } else {
                    hasRole = appRoles.some((role: string) => claimRoles.includes(this._getRoleName(role)));
                }

                return hasRole;
            }),
        );
    }

    /**
     * @param  {string} appRole
     * @returns string
     */
    private _getRoleName(appRole: string): string {
        let roleName: string = '';

        switch (appRole) {
            case 'admin':
                roleName = 'isg.Admin';
                break;
            case 'admin-ti':
                roleName = 'isg.AdminTi';
                break;
            case 'syndicate':
                roleName = 'isg.Syndicat';
                break;
            case 'manager':
                roleName = 'isg.Gestion';
                break;
            case 'user':
                roleName = 'isg.User';
                break;
        }

        return roleName;
    }
}
