import { Directive, Input, ViewContainerRef, TemplateRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityService } from '../services/security.service';

@Directive({
    selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
    public isVisible = false;
    private _unsubscribe$ = new Subject();

    @Input() appHasRole: string | string[];

    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _templateRef: TemplateRef<any>,
        private _securityService: SecurityService
    ) { }

    /**
     * @returns void
     */
    ngOnInit(): void {
        this._securityService.hasRole(this.appHasRole).pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((hasRole: any) => {
            if (hasRole) {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
                this.isVisible = true;
            } else {
                this._viewContainerRef.clear();
                this.isVisible = false;
            }
        }, (error: any) => console.log(error));
    }

    /**
     * @returns void
     */
    ngOnDestroy(): void {
        this._unsubscribe$.next(void 0);
        this._unsubscribe$.complete();
    }

}
