import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@app/environment';

if (environment.production) {
    enableProdMode();

    // Clear logs on prod env
    if (window) {
        window.console.log = function () { };
    }
} else {
    // eslint-disable-next-line no-restricted-syntax
    console.log(`Exécution sur l'environment: ${environment.name}`);
}

/* eslint-disable-next-line @typescript-eslint/no-floating-promises */
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
