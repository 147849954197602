import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks, LoadingBehavior } from '@ngxhq/common-ui';
import moment from 'moment';
import * as xlsx from 'xlsx';
import FileSaver from 'file-saver';
import { LazyLoadEvent, FilterMetadata, FilterService, MessageService, ConfirmationService } from 'primeng/api';
import {
    DossierDTO,
    InformationDTO,
    LocalSyndicaleDto,
    MotifDTO,
    PageResultDossierDTO,
    RegionSyndicaleDto,
    SousMotifDTO,
    RechercheDossierLegerDto
} from '../../../core/api/client/models';
import { Col } from '../../../models/col.interface';
import { LazyLoadingTable } from '../../../models/lazy-loading-table.interface';
import { GriefControllerService, PilotageControllerService } from '../../../core/api/client/services';
import { GrievancesService } from '../../../modules/grievances/services/grievances.service';
import { Router } from '@angular/router';
import { InfoService } from '../../../modules/grievances/services/info.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class TableComponent extends ComponentBase implements DoInit, OnChanges {
    public selectedData: DossierDTO[] = [];
    public table: PageResultDossierDTO;
    public first: number;
    public arraySelectedValue: string[] = [];
    public displayHistory: boolean;
    public historyGrief: any;
    public localSyndical: LocalSyndicaleDto[];
    public regionSyndicales: RegionSyndicaleDto[];
    public motif: MotifDTO[];
    public sousMotif: SousMotifDTO[];
    public callService: any;
    public dateDepotFiltre: any;
    public filterDropDown: any[] = [];
    public loadingExcelRest: boolean = false;
    public excelMore500: boolean = false;
    public context: string;
    public advancedSearchClick: boolean = false;
    public advancedSearchFilter: any;
    public excelClickEnabled: boolean = false;

    public filtresValue: any = {
        'statutLabel': [],
        'dateDepotDebut': '',
        'dateDepotFin': '',
        'numeroGriefLabel': '',
        'localSyndicalLabel': [],
        'regionSyndicaleLabel': [],
        'motifLabel': [],
        'sousMotifLabel': [],
        'requerantLabel': '',
        'dateDepotTri': ''
    };

    @Input() globalFilterFields: string[];
    @Input() cols: Col[];
    @Input() value: PageResultDossierDTO;
    @Input() excelFileName: string;
    @Input() dataKey: string;
    @Input() lazy: boolean;
    @Input() getUserInfo: InformationDTO;

    @Output() lazyLoading: EventEmitter<LazyLoadingTable> = new EventEmitter<LazyLoadingTable>();
    @Output() outputView: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputDelete: EventEmitter<DossierDTO | DossierDTO[]> = new EventEmitter<DossierDTO | DossierDTO[]>();
    @Output() outputEdit: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputDuplicate: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputHistorical: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() filters: EventEmitter<any> = new EventEmitter<any>();

    public infoApp$: Observable<InformationDTO | null> = this._infoAppService.infoApp$;

    constructor(
        private _filterService: FilterService,
        private _pilotageController: PilotageControllerService,
        private _grievancesService: GrievancesService,
        private _router: Router,
        private _infoAppService: InfoService,
        private _grievanceController: GriefControllerService,
        private _messageService: MessageService,
        private _datePipe: DatePipe,
        private _confirmationService: ConfirmationService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value.currentValue) {
            this._buildTable(changes.value.currentValue);

            const excelSession = sessionStorage.getItem('excelService');
            if (JSON.parse(excelSession!) === true) {
                this.excelClickEnabled = true;
            } else {
                this.excelClickEnabled = false;
            }

        }
        this.context = this._router.url.split('/')[3];
    }

    @LoadingBehavior()
    async doInit() {
        // Initialisation du composant...
        this.loadSessionStorage();

        await this._buildMutliSelectInput();
        this._filterService.register('dateRangeFilter', (value: any, filter: any): boolean => {
            const itemDate: string = moment(value).format('YYYY-MM-DD');
            const initDate: string = moment(filter[0]).format('YYYY-MM-DD');
            const endDate: string | null = filter[1] ? moment(filter[1]).format('YYYY-MM-DD') : null;
            return (endDate) ? moment(itemDate).isBetween(initDate, endDate, undefined, '[]') : moment(itemDate).isSame(initDate);
        });
    }

    /**
     * @returns Promise
     */
    private async _buildMutliSelectInput(): Promise<any> {
        if (sessionStorage.getItem('localSyndicals') === null) {
            this.localSyndical = await this._pilotageController.getAllLocalSyndicalsUsingGET().toPromise();
        } else {
            const localSyndicalsStorage: any = sessionStorage.getItem('localSyndicals');
            this.localSyndical = JSON.parse(localSyndicalsStorage);
        }

        if (sessionStorage.getItem('regionSyndicales') === null) {
            this.regionSyndicales = await this._pilotageController.getAllRegionSyndicalsUsingGET().toPromise();
        } else {
            const regionSyndicalesStorage: any = sessionStorage.getItem('regionSyndicales');
            this.regionSyndicales = JSON.parse(regionSyndicalesStorage);
        }

        if (sessionStorage.getItem('motif') === null) {
            this.motif = await this._pilotageController.getAllMotifsUsingGET().toPromise();
        } else {
            const motifStorage: any = sessionStorage.getItem('motif');
            this.motif = JSON.parse(motifStorage);
        }

        if (sessionStorage.getItem('sousMotif') === null) {
            this.sousMotif = await this._pilotageController.getAllSousMotifsUsingGET().toPromise();
        } else {
            const sousMotifStorage: any = sessionStorage.getItem('sousMotif');
            this.sousMotif = JSON.parse(sousMotifStorage);
        }

        return this.filterDropDown.push({
            localSyndicalLabel: this.localSyndical,
            regionSyndicaleLabel: this.regionSyndicales,
            motifLabel: this.motif,
            sousMotifLabel: this.sousMotif
        });
    }

    /**
     * @returns void
     */
    public loadSessionStorage(): void {
        const filtresSession = JSON.parse(sessionStorage.getItem('filtres')!);
        if (filtresSession !== null) {
            this.filtresValue = filtresSession;
            this.dateDepotFiltre = (filtresSession.dateDepotDebut !== '')
                ? [new Date(filtresSession.dateDepotDebut), (filtresSession.dateDepotFin) ? new Date(filtresSession.dateDepotFin) : null] // TO DO -1 date when new Date
                : '';
            this.filters.emit(filtresSession);
        }
    }

    /**
     * @param  {PageResultDossierDTO} value
     * @returns void
     */
    private _buildTable(value: PageResultDossierDTO): void {
        this.table = value;

        this.first = (this.table.number as number) * (this.table.pageSize as number);
        this.selectedData = [];
    }

    /**
     * @param  {LazyLoadEvent} event
     * @returns void
     */
    public onLazyLoad(event: LazyLoadEvent): void {
        const page: number = (event.first) ? event.first / (this.table.pageSize as number) : 0;
        this.lazyLoading.emit({ page, event });
    }

    /**
     * @param  {string} field
     * @param  {FilterMetadata} date
     * @param  {Table} dt
     * @returns void
     */
    public onDateChange(field: string, date: FilterMetadata | null, dt: Table): void {

        const lazyLoadMetadata: LazyLoadEvent = dt.createLazyLoadMetadata();
        if (lazyLoadMetadata.filters) {
            if (date) {
                lazyLoadMetadata.filters[field] = date;
            } else {
                delete lazyLoadMetadata.filters[field];
            }
        }
        this.onLazyLoad(lazyLoadMetadata);
    }

    public async sortDateDepot(event: any): Promise<void> {
        // IF click on ASC/DEC icon
        if ((event?.srcElement?.ariaSort === null)) {
            switch (event?.target?.className) {
                case 'p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt':
                    this.filtresValue.dateDepotTri = 'DESC';
                    break;
                case 'p-sortable-column-icon pi pi-fw pi-sort-amount-down':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
                case 'none':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
            }

            // ELSE (If user click on 'Date de dépôt' field)
        } else {
            switch (event?.srcElement?.ariaSort) {
                case 'ascending':
                    this.filtresValue.dateDepotTri = 'DESC';
                    break;
                case 'descending':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
                case 'none':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
            }
        }

        this.filters.emit(this.filtresValue);
    }

    /**
     * @param  {any} data
     * @param  {any} col
     * @returns void
     */
    public onChangeInput(data: any, col: any): void {
        if (this.filtresValue[col.field] === null || this.filtresValue[col.field] !== data) {
            if (col.field === 'numeroGriefLabel' || col.field === 'requerantLabel') {
                this.filtresValue[col.field] = data;
            } else if (col.field === 'dateDepot') {
                if (this.filtresValue.dateDepotDebut) {
                    this.filtresValue.dateDepotDebut = '';
                }
                if (this.filtresValue.dateDepotFin) {
                    this.filtresValue.dateDepotFin = '';
                }

                this.filtresValue.dateDepotDebut = new Date(data[0]).toISOString().split('T')[0];

                if (data[1] !== null) {
                    this.filtresValue.dateDepotFin = new Date(data[1]).toISOString().split('T')[0];
                }
            } else {
                this.filtresValue[col.field] = data;
            }
        }

        sessionStorage.setItem('filtres', JSON.stringify(this.filtresValue));
        this.filters.emit(this.filtresValue);
    }

    /**
     * @returns void
     */
    public onClearDate(): void {
        this.filtresValue.dateDepotDebut = '';
        this.filtresValue.dateDepotFin = '';
        const filtres = JSON.parse(sessionStorage.getItem('filtres') || '{}');

        if (filtres) {
            filtres.dateDepotDebut = '';
            filtres.dateDepotFin = '';
            sessionStorage.setItem('filtres', JSON.stringify(filtres));
        }
        this.dateDepotFiltre = null;

        this.filters.emit(this.filtresValue);
    }

    /**
     * @returns void
     */
    public clearFiltre(): void {
        this.filtresValue = {
            'statutLabel': [],
            'dateDepotDebut': '',
            'dateDepotFin': '',
            'numeroGriefLabel': '',
            'localSyndicalLabel': [],
            'regionSyndicaleLabel': [],
            'motifLabel': [],
            'sousMotifLabel': [],
            'requerantLabel': '',
            'dateDepotTri': ''
        };
        sessionStorage.removeItem('filtres');
        this.filters.emit(this.filtresValue);
    }

    /**
     * @param  {Table} table
     * @param  {string} fileName
     */
    public async exportExcel(table: Table, fileName: string): Promise<void> {
        (this.table?.totalElements! >= 500) ? this.excelMore500 = true : this.excelMore500 = false;
        this.loadingExcelRest = true;

        const context = this._router.url.split('/')[3];

        if (this.table?.totalElements! <= 500 && this.advancedSearchFilter?.length === undefined) {
            // ---------- Export Excel front-end with filters on table ----------

            sessionStorage.setItem('excelService', JSON.stringify(true));
            this.excelClickEnabled = true;

            switch (context) {
                case 'nouveaux-griefs':
                    await this._callServiceExcelFront(2, context);
                    break;

                case 'griefs-actifs':
                    await this._callServiceExcelFront(3, context);
                    break;

                case 'tous-les-griefs':
                    await this._callServiceExcelFront(1, context);
                    break;

                case 'mes-griefs':
                    await this._callServiceExcelFront(6, context);
                    break;

                case 'griefs-de-mon-mes-unites':
                    await this._callServiceExcelFront(7, context);
                    break;

                case 'prochainement-en-arbitrage':
                    await this._callServiceExcelFront(4, context);
                    break;

            };

        } else if (this.table?.totalElements! <= 500 && this.advancedSearchFilter?.length >= 1) {
            // ---------- Export Excel front-end with advanced filters ----------
            let isGestionnaire: boolean = false;
            this.advancedSearchFilter?.forEach((data: any) => {
                if (data?.critere?.libelle === 'Gestionnaire') {
                    isGestionnaire = true;
                }
            });

            if (isGestionnaire) {
                this.loadingExcelRest = false;

                this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
                this._confirmationService.confirm({
                    header: 'Attention!',
                    message: `
                    Veuillez prendre note que l'exportation d'excel avec le critère 'Gestionnaire' dans la recherche avancé n'est pas encore disponible. </br>
                    Cette mécanique sera disponible dans les prochaines versions de l'application. Merci de votre compréhension!
                    `,
                    acceptLabel: 'Ok',
                    rejectVisible: false,
                    accept: () => { }
                });

            } else {
                const searchBy: any = sessionStorage.getItem('advanced-searchBy');
                await this._grievancesService.getRechercheDossiers(JSON.parse(searchBy)).then((data: any) => {
                    this.callService = data;
                }).catch((err: any) => {
                    console.log(err);
                    this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
                });

                const data: any = (table.filteredValue)
                    ? this._buildDataForExportExcel(table.filteredValue)
                    : this._buildDataForExportExcel(this.callService?.body?.content);
                const worksheet = xlsx.utils.json_to_sheet(data);
                /* eslint-disable */
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                /* eslint-enable */
                const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this._saveAsExcelFile(excelBuffer, fileName);
            }

        } else {
            // ---------- Export Excel back-end (receive the excel by email) ----------

            const rechercheDossierLegerDto: RechercheDossierLegerDto = {
                dateDepotDebut: this.filtresValue?.dateDepotDebut,
                dateDepotFin: this.filtresValue?.dateDepotFin,
                dateDepotTri: this.filtresValue?.dateDepotTri,
                localSyndicalList: this.filtresValue?.localSyndicalLabel,
                motifList: this.filtresValue?.motifLabel,
                numeroGrief: this.filtresValue?.numeroGriefLabel,
                regionSyndicaleList: this.filtresValue?.regionSyndicaleLabel,
                requerant: this.filtresValue?.requerantLabel,
                sousMotifList: this.filtresValue?.sousMotifLabel,
                statutList: this.filtresValue?.statutLabel
            };

            switch (context) {
                case 'tous-les-griefs':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 1, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
                case 'nouveaux-griefs':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 2, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
                case 'griefs-actifs':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 3, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
                case 'prochainement-en-arbitrage':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 4, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
                case 'mes-griefs':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 6, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
                case 'griefs-de-mon-mes-unites':
                    this._grievanceController.rapportExcelUtilisateurUsingPOST({ categorie: 7, rechercheDossierLegerDto })
                        .pipe(takeUntil(this.destroyed))
                        .subscribe({
                            next: () => { },
                            error: (err: any) => console.log('excel end', err)
                        });
                    break;
            }
        }
    }

    private async _callServiceExcelFront(categorie: number, context: string): Promise<void> {
        await this._grievanceController.rapportExcelUtilisateurLocalBisUsingPOST({
            categorie: categorie, rechercheDossierLegerDto: {
                dateDepotDebut: this.filtresValue.dateDepotDebut,
                dateDepotFin: this.filtresValue.dateDepotFin,
                dateDepotTri: this.filtresValue.dateDepotTri,
                localSyndicalList: this.filtresValue.localSyndicalLabel,
                motifList: this.filtresValue.motifLabel,
                numeroGrief: this.filtresValue.numeroGriefLabel,
                regionSyndicaleList: this.filtresValue.regionSyndicaleLabel,
                requerant: this.filtresValue.requerantLabel,
                sousMotifList: this.filtresValue.sousMotifLabel,
                statutList: this.filtresValue.statutLabel
            }
        }).pipe(
            takeUntil(this.destroyed)
        ).subscribe({
            next: (data: any) => {
                this._exportExcel(data, context);
            },
            error: (err: any) => {
                console.log(err);
                this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
            }
        });

        this._clickExcelEvent();
    }

    /**
     * @param  {any} data
     * @returns void
     */
    private _exportExcel(data: any, context: string): void {
        const file: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileUrl = URL.createObjectURL(file);

        const fileLink = document.createElement('a');
        fileLink.href = fileUrl; //Add URL to the element
        fileLink.download = 'extraction_' + context + '_' + this._datePipe.transform(new Date(), 'yyyy-MM-dd'); //Add downloadname to the element
        fileLink.click();

        this.loadingExcelRest = false; // Disabled modal loading screen
    }

    /**
     * @param  {Array<any>} data
     * @returns Array
     */
    private _buildDataForExportExcel(data: Array<any>): Array<any> {
        return data.map((item: any) => {
            const res: any = {};
            for (const property in this.cols) {
                res[this.cols[property]['header']] = item[this.cols[property]['field']];
            }
            return res;
        });
    }

    /**
     * @param  {BlobPart} buffer
     * @param  {string} fileName
     * @returns void
     */
    private _saveAsExcelFile(buffer: BlobPart, fileName: string): void {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + moment().format('YYYY-MM-DD') + EXCEL_EXTENSION);

        this.loadingExcelRest = false; // Close popup
    }

    /**
     * @returns void
     */
    private _clickExcelEvent(): void {
        const click = sessionStorage.getItem('excelService');
        this.excelClickEnabled = JSON.parse(click!);
    }

    /**
     * @param  {Table} table
     * @returns void
     */
    public clear(table?: Table): void {
        if (table) {
            table.reset();
            table.filters = {};
        }
        sessionStorage.removeItem('statedemo-session');
    }

    /**
     * @param  {number} index
     * @returns number
     */
    public trackByFn(index: number): number {
        return index;
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public view(event: DossierDTO): void {
        this.outputView.emit(event);
    }

    /**
     * @param  {DossierDTO|DossierDTO[]} event
     * @returns void
     */
    public delete(event: DossierDTO | DossierDTO[]): void {
        this.outputDelete.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public edit(event: DossierDTO): void {
        this.outputEdit.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public duplicate(event: DossierDTO): void {
        this.outputDuplicate.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    @LoadingBehavior()
    public async historyView(event: DossierDTO): Promise<void> {
        this.historyGrief = await this._grievanceController.getGriefUsingGET(event?.numeroGrief!).toPromise();
        this.displayHistory = true;
    }
}
