import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks, LoadingBehavior } from '@ngxhq/common-ui';
import { LazyLoadEvent, MessageService, ConfirmationService } from 'primeng/api';
import {
    DossierDTO,
    InformationDTO,
    LocalSyndicaleDto,
    MotifDTO,
    PageResultDossierDTO,
    RegionSyndicaleDto,
    SousMotifDTO,
} from '../../../core/api/client/models';
import { Col } from '../../../models/col.interface';
import { LazyLoadingTable } from '../../../models/lazy-loading-table.interface';
import { GriefControllerService, PilotageControllerService } from '../../../core/api/client/services';
import { GrievancesService } from '../../../modules/grievances/services/grievances.service';
import { Router } from '@angular/router';
import { InfoService } from '../../../modules/grievances/services/info.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-table-advanced-search',
    templateUrl: './table-advanced-search.component.html',
    styleUrls: ['./table-advanced-search.component.scss']
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class TableAdvancedSearchComponent extends ComponentBase implements DoInit, OnChanges {
    public selectedData: DossierDTO[] = [];
    public table: PageResultDossierDTO;
    public first: number;
    public arraySelectedValue: string[] = [];
    public displayHistory: boolean;
    public historyGrief: any;
    public localSyndical: LocalSyndicaleDto[];
    public regionSyndicales: RegionSyndicaleDto[];
    public motif: MotifDTO[];
    public sousMotif: SousMotifDTO[];
    public callService: any;
    public dateDepotFiltre: any;
    public filterDropDown: any[] = [];
    public loadingExcelRest: boolean = false;
    public excelMore500: boolean = false;
    public advancedSearchClick: boolean = false;
    public advancedSearchFilter: any;
    public excelClickEnabled: boolean = false;

    public filtresValue: any = {
        'statutLabel': [],
        'dateDepotDebut': '',
        'dateDepotFin': '',
        'numeroGriefLabel': '',
        'localSyndicalLabel': [],
        'regionSyndicaleLabel': [],
        'motifLabel': [],
        'sousMotifLabel': [],
        'requerantLabel': '',
        'dateDepotTri': ''
    };

    @Input() globalFilterFields: string[];
    @Input() cols: Col[];
    @Input() value: PageResultDossierDTO;
    @Input() excelFileName: string;
    @Input() dataKey: string;
    @Input() lazy: boolean;
    @Input() getUserInfo: InformationDTO;

    @Output() lazyLoading: EventEmitter<LazyLoadingTable> = new EventEmitter<LazyLoadingTable>();
    @Output() outputView: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputDelete: EventEmitter<DossierDTO | DossierDTO[]> = new EventEmitter<DossierDTO | DossierDTO[]>();
    @Output() outputEdit: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputDuplicate: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() outputHistorical: EventEmitter<DossierDTO> = new EventEmitter<DossierDTO>();
    @Output() filters: EventEmitter<any> = new EventEmitter<any>();

    public infoApp$: Observable<InformationDTO | null> = this._infoAppService.infoApp$;

    constructor(
        public _pilotageController: PilotageControllerService,
        public _grievancesService: GrievancesService,
        public _router: Router,
        public _infoAppService: InfoService,
        public _grievanceController: GriefControllerService,
        public _messageService: MessageService,
        public _datePipe: DatePipe,
        public _confirmationService: ConfirmationService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.value.currentValue) {
            this.buildTable(changes.value.currentValue);


            if (sessionStorage.getItem('advanced-search') === null) {
                this.advancedSearchClick = false;
                this.advancedSearchFilter = null;
            } else {
                this.advancedSearchClick = true;
                const advancedSearch: any = sessionStorage.getItem('advanced-search');
                this.advancedSearchFilter = JSON.parse(advancedSearch);
            }

            const excelSession = sessionStorage.getItem('excelService');
            if (JSON.parse(excelSession!) === true) {
                this.excelClickEnabled = true;
            } else {
                this.excelClickEnabled = false;
            }
        }
    }

    @LoadingBehavior()
    async doInit() { }

    /**
     * @param  {PageResultDossierDTO} value
     * @returns void
     */
    public buildTable(value: PageResultDossierDTO): void {
        this.table = value;

        this.first = (this.table.number as number) * (this.table.pageSize as number);
        this.selectedData = [];
    }

    /**
     * @param  {LazyLoadEvent} event
     * @returns void
     */
    public onLazyLoad(event: LazyLoadEvent): void {
        const page: number = (event.first) ? event.first / (this.table.pageSize as number) : 0;
        this.lazyLoading.emit({ page, event });
    }

    public async sortDateDepot(event: any): Promise<void> {
        // IF click on ASC/DEC icon
        if ((event?.srcElement?.ariaSort === null)) {
            switch (event?.target?.className) {
                case 'p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt':
                    this.filtresValue.dateDepotTri = 'DESC';
                    break;
                case 'p-sortable-column-icon pi pi-fw pi-sort-amount-down':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
                case 'none':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
            }

            // ELSE (If user click on 'Date de dépôt' field)
        } else {
            switch (event?.srcElement?.ariaSort) {
                case 'ascending':
                    this.filtresValue.dateDepotTri = 'DESC';
                    break;
                case 'descending':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
                case 'none':
                    this.filtresValue.dateDepotTri = 'ASC';
                    break;
            }
        }

        this.filters.emit(this.filtresValue);
    }

    /**
     * @returns void
     */
    public async exportExcel(): Promise<void> {
        (this.table?.totalElements! >= 500 || !this.advancedSearchFilter) ? this.excelMore500 = true : this.excelMore500 = false;

        this.loadingExcelRest = true;
        let isGestionnaire: boolean = false;
        let criteres: any;
        const rechercheAvanceeCritere: any = sessionStorage.getItem('advanced-searchBy');
        const nbGriefs: number = this.table?.totalElements!;

        rechercheAvanceeCritere === null ? criteres = { criteres: [] } : criteres = JSON.parse(rechercheAvanceeCritere);

        this.advancedSearchFilter?.forEach((data: any) => {
            if (data?.critere?.libelle === 'Gestionnaire') {
                isGestionnaire = true;
            }
        });
        if (isGestionnaire) {
            this.loadingExcelRest = false;
            this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
            this._confirmationService.confirm({
                header: 'Attention!',
                message: `
                Veuillez prendre note que l'exportation d'excel avec le critère 'Gestionnaire' dans la recherche avancé n'est pas encore disponible. </br>
                Cette mécanique sera disponible dans les prochaines versions de l'application. Merci de votre compréhension!
                `,
                acceptLabel: 'Ok',
                rejectVisible: false,
                accept: () => { }
            });
        }

        if (nbGriefs <= 500 && rechercheAvanceeCritere !== null) {
            this.createExcelFileUnder500Grievances(criteres)
                .catch(error => {
                    console.error('Erreur dans la génération du fichier Excel (<500)', error);
                });
        } else {
            this.loadingExcelRest = false;
            this._confirmationService.confirm({
                header: 'Attention!',
                message: `
                L'extraction comporte ${rechercheAvanceeCritere === null ? 'tous les' : nbGriefs} griefs, voulez-vous continuer ? </br>
                L'envoie pourrait prendre jusqu'à 30 minutes dépendamment de la taille du fichier.
                `,
                acceptLabel: 'Ok',
                rejectLabel: 'Annuler',
                rejectVisible: true,
                rejectButtonStyleClass: 'p-button-outlined',
                accept: () => {
                    this._grievanceController.exportRechercheDossiersPOST(criteres).pipe(
                        takeUntil(this.destroyed)
                    ).subscribe({
                        next: () => { },
                        error: (err: any) => {
                            if (err.status !== 504) {
                                console.error('Erreur dans la génération du fichier Excel (>500)', err);
                            }
                        }

                    });
                }
            });
        }
    }

    /**
     * @param  {any} rechercheAvanceeCritere
     * @returns void
     */
    public async createExcelFileUnder500Grievances(rechercheAvanceeCritere: any): Promise<void> {
        this._grievanceController.exportRechercheDossiersPOST(rechercheAvanceeCritere)
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe({
                next: (data: Blob) => {
                    this.downloadingExcelFileUnder500Grievances(data);
                },
                error: (err: any) => {
                    console.log(err);
                    if (err.status !== 504) {
                        this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
                    }
                }
            });

        this.clickExcelEvent();
    }

    /**
     * @param  {any} data
     * @returns void
     */
    public downloadingExcelFileUnder500Grievances(data: any): void {
        const file: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileUrl = URL.createObjectURL(file);

        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.download = 'extraction_rechercheAvancee' + '_' + this._datePipe.transform(new Date(), 'yyyy-MM-dd');
        fileLink.click();

        this.loadingExcelRest = false; // Disabled modal loading screen
    }

    /**
     * @returns void
     */
    public clickExcelEvent(): void {
        const click = sessionStorage.getItem('excelService');
        this.excelClickEnabled = JSON.parse(click!);
    }

    /**
     * @param  {number} index
     * @returns number
     */
    public trackByFn(index: number): number {
        return index;
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public view(event: DossierDTO): void {
        this.outputView.emit(event);
    }

    /**
     * @param  {DossierDTO|DossierDTO[]} event
     * @returns void
     */
    public delete(event: DossierDTO | DossierDTO[]): void {
        this.outputDelete.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public edit(event: DossierDTO): void {
        this.outputEdit.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    public duplicate(event: DossierDTO): void {
        this.outputDuplicate.emit(event);
    }

    /**
     * @param  {DossierDTO} event
     * @returns void
     */
    @LoadingBehavior()
    public async historyView(event: DossierDTO): Promise<void> {
        this.historyGrief = await this._grievanceController.getGriefUsingGET(event?.numeroGrief!).toPromise();
        this.displayHistory = true;
    }
}
