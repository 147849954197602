import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { ThemeHqPrimeNgA11yModule } from '@ngxhq/theme-ngxhq';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

/*Shared Module*/
import { SharedModule } from './shared/shared.module';

/* App Root */
import { AppComponent } from './app.component';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';

/* Feature Modules */
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { SharedPrimeNgModule } from './shared/shared-primeng.module';
import { ErrorInterceptor } from './interceptors/error.interceptor';
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LogoutComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ButtonModule,
        ThemeHqPrimeNgA11yModule,
        SharedPrimeNgModule,

        /* Non-lazy loaded Feature Modules */
        CoreModule,
        ShellModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-CA' },
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() { }
}
