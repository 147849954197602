/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InformationDTO } from '../models/information-dto';
import { UtilisateurInfoSapDto } from '../models/utilisateur-info-sap-dto';
import { CritereWebRecherche } from '../models/critere-web-recherche';
import { RHSigaLireInfoServiceResponse } from '../models/rhsiga-lire-info-service-response';
import { IdentiteRH } from '../models/identite-rh';

/**
 * Info Controller
 */
@Injectable({
    providedIn: 'root',
})
class InfoService extends __BaseService {
    static readonly getInformationUsingGETPath = '/api/v1/info';
    static readonly miseAJourUtilisateursUsingGETPath = '/api/v1/info/MiseAJourUtilisateurs';
    static readonly getUserMatriculeInformationUsingGETPath = '/api/v1/info/infoMatriculeUtilisateur';
    static readonly getUserInformationUsingGETPath = '/api/v1/info/infoUtilisateur';
    static readonly listeCritereUsingGETPath = '/api/v1/info/listeCritere';
    static readonly getInformationLoginUsingGETPath = '/api/v1/info/login';
    static readonly getSAPInformationUsingGETPath = '/api/v1/info/sapUtilisateur';
    static readonly getSAPInformationMatriculeUsingGETPath = '/api/v1/info/sapUtilisateurMatricule';
    static readonly updateMatriculeUtilisateursUsingGETPath = '/api/v1/info/updateMatriculeUtilisateurs';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * Information d'initialisation
     * @return Requête traitée avec succès
     */
    getInformationUsingGETResponse(): __Observable<__StrictHttpResponse<InformationDTO>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders(
            /* {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*'
            } */
        );
        let __body: any = null;
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<InformationDTO>;
            })
        );
    }
    /**
     * Information d'initialisation
     * @return Requête traitée avec succès
     */
    getInformationUsingGET(): __Observable<InformationDTO> {
        return this.getInformationUsingGETResponse().pipe(
            __map(_r => _r.body as InformationDTO)
        );
    }

    /**
     * Test Batch utilisateurs
     * @return Requête traitée avec succès
     */
    miseAJourUtilisateursUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/MiseAJourUtilisateurs`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'text'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<string>;
            })
        );
    }
    /**
     * Test Batch utilisateurs
     * @return Requête traitée avec succès
     */
    miseAJourUtilisateursUsingGET(): __Observable<string> {
        return this.miseAJourUtilisateursUsingGETResponse().pipe(
            __map(_r => _r.body as string)
        );
    }

    /**
     * info sur utilisateur par matricule
     * @param matricule Le matricule recherche
     * @return Requête traitée avec succès
     */
    getUserMatriculeInformationUsingGETResponse(matricule?: string): __Observable<__StrictHttpResponse<UtilisateurInfoSapDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (matricule != null) __params = __params.set('matricule', matricule.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/infoMatriculeUtilisateur`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<UtilisateurInfoSapDto>;
            })
        );
    }
    /**
     * info sur utilisateur par matricule
     * @param matricule Le matricule recherche
     * @return Requête traitée avec succès
     */
    getUserMatriculeInformationUsingGET(matricule?: string): __Observable<UtilisateurInfoSapDto> {
        return this.getUserMatriculeInformationUsingGETResponse(matricule).pipe(
            __map(_r => _r.body as UtilisateurInfoSapDto)
        );
    }

    /**
     * info sur utilisateur
     * @param cii Le cii recherche
     * @return Requête traitée avec succès
     */
    getUserInformationUsingGETResponse(cii?: string): __Observable<__StrictHttpResponse<UtilisateurInfoSapDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (cii != null) __params = __params.set('cii', cii.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/infoUtilisateur`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<UtilisateurInfoSapDto>;
            })
        );
    }
    /**
     * info sur utilisateur
     * @param cii Le cii recherche
     * @return Requête traitée avec succès
     */
    getUserInformationUsingGET(cii?: string): __Observable<UtilisateurInfoSapDto> {
        return this.getUserInformationUsingGETResponse(cii).pipe(
            __map(_r => _r.body as UtilisateurInfoSapDto)
        );
    }

    /**
     * liste des criteres de recherche
     * @return OK
     */
    listeCritereUsingGETResponse(): __Observable<__StrictHttpResponse<Array<CritereWebRecherche>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/listeCritere`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<CritereWebRecherche>>;
            })
        );
    }
    /**
     * liste des criteres de recherche
     * @return OK
     */
    listeCritereUsingGET(): __Observable<Array<CritereWebRecherche>> {
        return this.listeCritereUsingGETResponse().pipe(
            __map(_r => _r.body as Array<CritereWebRecherche>)
        );
    }

    /**
     * Information d'initialisation au login
     * @return Requête traitée avec succès
     */
    getInformationLoginUsingGETResponse(): __Observable<__StrictHttpResponse<InformationDTO>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/login`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<InformationDTO>;
            })
        );
    }
    /**
     * Information d'initialisation au login
     * @return Requête traitée avec succès
     */
    getInformationLoginUsingGET(): __Observable<InformationDTO> {
        return this.getInformationLoginUsingGETResponse().pipe(
            __map(_r => _r.body as InformationDTO)
        );
    }

    /**
     * Test SAP
     * @param cii Le cii recherche
     * @return Requête traitée avec succès
     */
    getSAPInformationUsingGETResponse(cii?: string): __Observable<__StrictHttpResponse<RHSigaLireInfoServiceResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (cii != null) __params = __params.set('cii', cii.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/sapUtilisateur`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<RHSigaLireInfoServiceResponse>;
            })
        );
    }
    /**
     * Test SAP
     * @param cii Le cii recherche
     * @return Requête traitée avec succès
     */
    getSAPInformationUsingGET(cii?: string): __Observable<RHSigaLireInfoServiceResponse> {
        return this.getSAPInformationUsingGETResponse(cii).pipe(
            __map(_r => _r.body as RHSigaLireInfoServiceResponse)
        );
    }

    /**
     * Test SAP
     * @param matricule La matricule recherche
     * @return Requête traitée avec succès
     */
    getSAPInformationMatriculeUsingGETResponse(matricule?: string): __Observable<__StrictHttpResponse<IdentiteRH>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (matricule != null) __params = __params.set('matricule', matricule.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/sapUtilisateurMatricule`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<IdentiteRH>;
            })
        );
    }
    /**
     * Test SAP
     * @param matricule La matricule recherche
     * @return Requête traitée avec succès
     */
    getSAPInformationMatriculeUsingGET(matricule?: string): __Observable<IdentiteRH> {
        return this.getSAPInformationMatriculeUsingGETResponse(matricule).pipe(
            __map(_r => _r.body as IdentiteRH)
        );
    }

    /**
     * updateMatriculeUtilisateurs
     * @return OK
     */
    updateMatriculeUtilisateursUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/info/updateMatriculeUtilisateurs`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'text'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<string>;
            })
        );
    }
    /**
     * updateMatriculeUtilisateurs
     * @return OK
     */
    updateMatriculeUtilisateursUsingGET(): __Observable<string> {
        return this.updateMatriculeUtilisateursUsingGETResponse().pipe(
            __map(_r => _r.body as string)
        );
    }
}

module InfoService {
}

export { InfoService }
