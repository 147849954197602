<p-toast></p-toast>

<p-table #dt [columns]="cols" [value]="$any(table)?.content" [(selection)]="selectedData" [dataKey]="dataKey"
    [paginator]="true" [rows]="8" [showCurrentPageReport]="true" [totalRecords]="$any(table)?.totalElements"
    [lazy]="false" (onLazyLoad)="onLazyLoad($event)" [(first)]="first" [rowTrackBy]="trackByFn" [lazyLoadOnInit]="false"
    [rowsPerPageOptions]="[8,16,32]"
    currentPageReportTemplate="Affichage de {first} à {last} entrées sur {totalRecords}"
    class="hq-form hq-form-layout hq-form-layout-outline">
    <ng-template pTemplate="caption">
        <div class="p-datatable-caption">
            <p-button type="button" icon="fas fa-file-excel"
                class="p-button-rounded p-button-success p-button-outlined header-btn" title="Exporter XLSX"
                *appHasRole="['user', 'admin', 'manager', 'syndicate']" (click)="exportExcel()">
            </p-button>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 2%"></th>
            <ng-container *ngFor="let col of columns">
                <th id="test" *ngIf="col.visible && col.field === 'dateDepot'" [pSortableColumn]="col?.field"
                    (click)="sortDateDepot($event)" [ngStyle]="{'width': (82 / columns?.length) + '%'}">
                    {{col?.header}}
                    <p-sortIcon [field]="col?.field"></p-sortIcon>
                </th>
                <th *ngIf="col.visible && col.field !== 'dateDepot'"
                    [ngStyle]="{'width': (82 / columns?.length) + '%'}">
                    {{col?.header}}
                </th>
            </ng-container>
            <th style="width: 8%; text-align: center">Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *appHasRole="['admin', 'admin-ti']">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td *appHasRole="['user', 'manager', 'syndicate']"></td>
            <ng-container *ngFor="let col of columns">
                <td *ngIf="col.visible" [title]="rowData[col?.field]">
                    <ng-container *ngIf="col?.type === 'string' || col.type === 'multiSelect'">
                        {{ rowData[col?.field] }}
                    </ng-container>
                    <ng-container *ngIf="col?.type === 'date'">
                        {{ rowData[col?.field] | date: 'd MMM yyyy' }}
                    </ng-container>
                </td>
            </ng-container>
            <td class="text-center">
                <button pButton pRipple type="button" icon="fas fa-eye" class="p-button-rounded p-button-text"
                    *appHasRole="['admin-ti', 'admin', 'user', 'syndicate', 'manager']"
                    [disabled]="$any(selectedData)?.length > 1" title="Afficher" (click)="view(rowData)"></button>
                <button pButton pRipple type="button" icon="appicons ic-edit" class="p-button-rounded p-button-text"
                    [disabled]="$any(selectedData)?.length > 1" title="Modifer"
                    *appHasRole="['user', 'admin', 'admin-ti']" (click)="edit(rowData)"></button>
                <button pButton pRipple type=" button" icon="fas fa-history" class="p-button-rounded p-button-text"
                    [disabled]="$any(selectedData)?.length > 1" *appHasRole="['user', 'manager', 'admin', 'admin-ti']"
                    title="Historique" (click)="historyView(rowData)"></button>
                <button pButton pRipple type=" button" icon="appicons ic-copy" class="p-button-rounded p-button-text"
                    [disabled]="$any(selectedData)?.length > 1" title="Dupliquer"
                    *appHasRole="['admin', 'admin-ti', 'syndicate']" (click)="duplicate(rowData)"></button>
                <button pButton pRipple type=" button" icon="appicons ic-times" class="p-button-rounded p-button-text"
                    [disabled]="$any(selectedData)?.length >= 1 || getUserInfo?.privilege === 'Administrateur-TI'"
                    title="Supprimer" *appHasRole="['admin', 'admin-ti']" (click)="delete(rowData)"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr *ngIf="(filtresValue.dateDepotDebut !== ''
        || filtresValue.localSyndicalLabel.length !== 0
        || filtresValue.motifLabel.length !== 0
        || filtresValue.numeroGriefLabel !== ''
        || filtresValue.regionSyndicaleLabel.length !== 0
        || filtresValue.requerantLabel !== ''
        || filtresValue.sousMotifLabel.length !== 0
        || filtresValue.statutLabel.length !== 0) || advancedSearchClick" style="border-bottom: 1px solid #c8c8c8;">
            <td colspan="6" style="color:'#1b75bb'">Total de {{value?.totalElements}} griefs selon les critères
                demandés
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Historique" [(visible)]="displayHistory" [modal]="true" [style]="{height: '70vh', width: '50vw'}"
    class="dialog" [draggable]="false" [resizable]="false">
    <p-header>
        <div>
            <i class="fas fa-history mr-2"></i>Historique
        </div>
        <span style="font-size: 1rem;">Numéro du
            {{(historyGrief?.localSyndical?.name === 'APCPNHQ') ? 'différent': 'grief'}} :
            {{historyGrief?.numeroGrief}}</span>
    </p-header>
    <p-timeline [value]="historyGrief?.historiqueDossierList">
        <ng-template pTemplate="content" let-item>
            <div class="mb-1">
                <span class="p-text-secondary">{{item.type}}</span>
            </div>
            <div class="mb-5" *ngIf="item.valeurAvant || item.valeurApres">
                <small class="p-text-secondary">Valeur avant : {{item.valeurAvant}}</small><br>
                <small class="p-text-secondary">Valeur après : {{item.valeurApres}}</small>
            </div>

        </ng-template>
        <ng-template pTemplate="opposite" let-item>
            {{item.nomCompletUtilisateur}}, {{item.dateHistorique}}
        </ng-template>
    </p-timeline>
</p-dialog>

<p-dialog header="Production du fichier Excel" [(visible)]="loadingExcelRest" [draggable]="false" [resizable]="false"
    [closable]="false" [style]="{width: '35vw', height: '30vh'}" class="dialog"
    [style.display]="!loadingExcelRest ? 'none' : ''">
    <p *ngIf="excelMore500">L'exécution du rapport est en cours et celui-ci sera envoyé par courriel. L'envoie
        pourrait prendre jusqu'à 30 minutes dépendamment de la taille du fichier.</p>
    <div *ngIf="!excelMore500" class="flex flex-column justify-content-center align-items-center h-full">
        <p-progressSpinner class="mt-2"></p-progressSpinner>
        <span>L'exécution du rapport est en cours et celui-ci apparaitra au bas de l'écran dans les
            prochaines minutes.</span>
    </div>
    <ng-template pTemplate="footer">
        <p-button *ngIf="excelMore500" class="flex justify-content-end" icon="pi pi-check"
            (click)="loadingExcelRest=false" label="Ok" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>