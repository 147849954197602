import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@app/environment';
import {
    BusyModule,
    BusyRouterService,
    BusyService,
    CommonUiModule,
    LoadingBehaviorsModule,
    ShellModule,
} from '@ngxhq/common-ui';
import { ConfigModule } from '@ngxhq/config';
import { APP_ENVIRONMENT_CONFIG, OpenIdModule, SecurityModule } from '@ngxhq/security';
import { UtilitiesModule } from '@ngxhq/utilities';
import { GlobalErrorComponent, GlobalErrorHandler } from './errors';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ApiModule } from './api/client/api.module';
@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule.forRoot(),
        ConfigModule,
        ConfigModule.forRoot(),
        OpenIdModule.forRoot(),
        SecurityModule.forRoot(),
        CommonUiModule,
        CommonUiModule.forRoot(),
        ShellModule.forRoot(),
        BusyModule,
        BusyModule.forRoot(),
        LoadingBehaviorsModule.forRoot(),
        ApiModule.forRoot({ rootUrl: '[CONFIG:BACKEND_BASE_URL]' }),
    ],
    exports: [
        CommonModule,
        UtilitiesModule,
        ConfigModule,
        SecurityModule,
        CommonUiModule,
    ],
    declarations: [
        GlobalErrorComponent,
    ],
    providers: [
        BusyRouterService,
        BusyService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: APP_ENVIRONMENT_CONFIG,
            useValue: environment
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
