import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@ngxhq/common-ui';
import { ButtonModule } from 'primeng/button';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ButtonModule,
    ],
    exports: [
        MainMenuComponent,
        HeaderComponent,
    ],
    declarations: [
        MainMenuComponent,
        HeaderComponent,
    ],
    providers: [
    ],
})
export class ShellModule {
}
