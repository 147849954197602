/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ArbitreDTO } from '../models/arbitre-dto';
import { LocalSyndicaleDto } from '../models/local-syndicale-dto';
import { MotifDTO } from '../models/motif-dto';
import { ParamApplicationDTO } from '../models/param-application-dto';
import { ProcureurPatronalDTO } from '../models/procureur-patronal-dto';
import { ProcureurSyndicalDTO } from '../models/procureur-syndical-dto';
import { RegionSyndicaleDto } from '../models/region-syndicale-dto';
import { ResponsableRHDTO } from '../models/responsable-rhdto';
import { SousMotifDTO } from '../models/sous-motif-dto';
import { TypePieceJointeDto } from '../models/type-piece-jointe-dto';
import { UtilisateurDTO } from '../models/utilisateur-dto';

/**
 * Pilotage Controller
 */
@Injectable({
  providedIn: 'root',
})
class PilotageControllerService extends __BaseService {
  static readonly getAllArbitresUsingGETPath = '/api/v1/pilotage/arbitres';
  static readonly ajouterArbitreUsingPOSTPath = '/api/v1/pilotage/arbitres';
  static readonly getArbitreUsingGETPath = '/api/v1/pilotage/arbitres/{id}';
  static readonly updateArbitreUsingPUTPath = '/api/v1/pilotage/arbitres/{id}';
  static readonly deleteArbitreUsingDELETEPath = '/api/v1/pilotage/arbitres/{id}';
  static readonly patchArbitreUsingPATCHPath = '/api/v1/pilotage/arbitres/{id}';
  static readonly getAllArbitresActifUsingGETPath = '/api/v1/pilotage/arbitresActifs';
  static readonly getAllLocalSyndicalsUsingGETPath = '/api/v1/pilotage/localSyndicals';
  static readonly getAllMotifsUsingGETPath = '/api/v1/pilotage/motifs';
  static readonly ajouterMotifUsingPOSTPath = '/api/v1/pilotage/motifs';
  static readonly getMotifUsingGETPath = '/api/v1/pilotage/motifs/{id}';
  static readonly updateMotifUsingPUTPath = '/api/v1/pilotage/motifs/{id}';
  static readonly deleteMotifUsingDELETEPath = '/api/v1/pilotage/motifs/{id}';
  static readonly patchMotifUsingPATCHPath = '/api/v1/pilotage/motifs/{id}';
  static readonly getAllParamApplicationUsingGETPath = '/api/v1/pilotage/paramApplications';
  static readonly ajouterUsingPOSTPath = '/api/v1/pilotage/paramApplications';
  static readonly getExempleUsingGETPath = '/api/v1/pilotage/paramApplications/{id}';
  static readonly updateUsingPUTPath = '/api/v1/pilotage/paramApplications/{id}';
  static readonly deleteExempleUsingDELETEPath = '/api/v1/pilotage/paramApplications/{id}';
  static readonly patchUsingPATCHPath = '/api/v1/pilotage/paramApplications/{id}';
  static readonly getPatronalNextSequenceUsingGETPath = '/api/v1/pilotage/patronalNextSequence';
  static readonly getAllProcureurPatronalsUsingGETPath = '/api/v1/pilotage/procureurPatronals';
  static readonly ajouterProcureurPatronalUsingPOSTPath = '/api/v1/pilotage/procureurPatronals';
  static readonly getProcureurPatronalUsingGETPath = '/api/v1/pilotage/procureurPatronals/{id}';
  static readonly updateProcureurPatronalUsingPUTPath = '/api/v1/pilotage/procureurPatronals/{id}';
  static readonly deleteProcureurPatronalUsingDELETEPath = '/api/v1/pilotage/procureurPatronals/{id}';
  static readonly patchProcureurPatronalUsingPATCHPath = '/api/v1/pilotage/procureurPatronals/{id}';
  static readonly getAllProcureurPatronalsActifUsingGETPath = '/api/v1/pilotage/procureurPatronalsActifs';
  static readonly getAllProcureurSyndicalsUsingGETPath = '/api/v1/pilotage/procureurSyndicals';
  static readonly ajouterProcureurSyndicalUsingPOSTPath = '/api/v1/pilotage/procureurSyndicals';
  static readonly getProcureurSyndicalUsingGETPath = '/api/v1/pilotage/procureurSyndicals/{id}';
  static readonly updateProcureurSyndicalUsingPUTPath = '/api/v1/pilotage/procureurSyndicals/{id}';
  static readonly deleteProcureurSyndicalUsingDELETEPath = '/api/v1/pilotage/procureurSyndicals/{id}';
  static readonly patchProcureurSyndicalUsingPATCHPath = '/api/v1/pilotage/procureurSyndicals/{id}';
  static readonly getAllProcureurSyndicalsActifUsingGETPath = '/api/v1/pilotage/procureurSyndicalsActifs';
  static readonly getAllRegionSyndicalsUsingGETPath = '/api/v1/pilotage/regionSyndicals';
  static readonly getRegionSyndicalesReferencesUsingGETPath = '/api/v1/pilotage/regionSyndicals/localSyndicalReference/{id}';
  static readonly getAllResponsableRHsUsingGETPath = '/api/v1/pilotage/responsableRHs';
  static readonly ajouterResponsableRHUsingPOSTPath = '/api/v1/pilotage/responsableRHs';
  static readonly getResponsableRHUsingGETPath = '/api/v1/pilotage/responsableRHs/{id}';
  static readonly updateResponsableRHUsingPUTPath = '/api/v1/pilotage/responsableRHs/{id}';
  static readonly deleteResponsableRHUsingDELETEPath = '/api/v1/pilotage/responsableRHs/{id}';
  static readonly patchResponsableRHUsingPATCHPath = '/api/v1/pilotage/responsableRHs/{id}';
  static readonly getAllSousMotifsUsingGETPath = '/api/v1/pilotage/sousMotifs';
  static readonly ajouterSousMotifUsingPOSTPath = '/api/v1/pilotage/sousMotifs';
  static readonly getSousMotifUsingGETPath = '/api/v1/pilotage/sousMotifs/{id}';
  static readonly updateSousMotifUsingPUTPath = '/api/v1/pilotage/sousMotifs/{id}';
  static readonly deleteSousMotifUsingDELETEPath = '/api/v1/pilotage/sousMotifs/{id}';
  static readonly patchSousMotifUsingPATCHPath = '/api/v1/pilotage/sousMotifs/{id}';
  static readonly getAllTypePieceJointesUsingGETPath = '/api/v1/pilotage/typePieceJointes';
  static readonly ajouterTypePieceJointeUsingPOSTPath = '/api/v1/pilotage/typePieceJointes';
  static readonly getTypePieceJointeUsingGETPath = '/api/v1/pilotage/typePieceJointes/{id}';
  static readonly updateTypePieceJointeUsingPUTPath = '/api/v1/pilotage/typePieceJointes/{id}';
  static readonly deleteTypePieceJointeUsingDELETEPath = '/api/v1/pilotage/typePieceJointes/{id}';
  static readonly patchTypePieceJointeUsingPATCHPath = '/api/v1/pilotage/typePieceJointes/{id}';
  static readonly getAllUtilisateursUsingGETPath = '/api/v1/pilotage/utilisateurs';
  static readonly ajouterUtilisateurUsingPOSTPath = '/api/v1/pilotage/utilisateurs';
  static readonly getUtilisateurUsingGETPath = '/api/v1/pilotage/utilisateurs/{id}';
  static readonly updateUtilisateurUsingPUTPath = '/api/v1/pilotage/utilisateurs/{id}';
  static readonly deleteUtilisateurfUsingDELETEPath = '/api/v1/pilotage/utilisateurs/{id}';
  static readonly patchUtilisateurUsingPATCHPath = '/api/v1/pilotage/utilisateurs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Obtenir toutes les arbitres
   * @return Requête traitée avec succès
   */
  getAllArbitresUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ArbitreDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/arbitres`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ArbitreDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les arbitres
   * @return Requête traitée avec succès
   */
  getAllArbitresUsingGET(): __Observable<Array<ArbitreDTO>> {
    return this.getAllArbitresUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ArbitreDTO>)
    );
  }

  /**
   * Ajouter un arbitre
   * @param arbitreDTO arbitreDTO
   * @return OK
   */
  ajouterArbitreUsingPOSTResponse(arbitreDTO: ArbitreDTO): __Observable<__StrictHttpResponse<ArbitreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = arbitreDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/arbitres`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArbitreDTO>;
      })
    );
  }
  /**
   * Ajouter un arbitre
   * @param arbitreDTO arbitreDTO
   * @return OK
   */
  ajouterArbitreUsingPOST(arbitreDTO: ArbitreDTO): __Observable<ArbitreDTO> {
    return this.ajouterArbitreUsingPOSTResponse(arbitreDTO).pipe(
      __map(_r => _r.body as ArbitreDTO)
    );
  }

  /**
   * Obtenir un arbitre
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getArbitreUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ArbitreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/arbitres/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArbitreDTO>;
      })
    );
  }
  /**
   * Obtenir un arbitre
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getArbitreUsingGET(id: string): __Observable<ArbitreDTO> {
    return this.getArbitreUsingGETResponse(id).pipe(
      __map(_r => _r.body as ArbitreDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateArbitreUsingPUTParams` containing the following parameters:
   *
   * - `id`: La clé de l'objet
   *
   * - `arbitreDTO`: arbitreDTO
   *
   * @return OK
   */
  updateArbitreUsingPUTResponse(params: PilotageControllerService.UpdateArbitreUsingPUTParams): __Observable<__StrictHttpResponse<ArbitreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.arbitreDTO;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/arbitres/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArbitreDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateArbitreUsingPUTParams` containing the following parameters:
   *
   * - `id`: La clé de l'objet
   *
   * - `arbitreDTO`: arbitreDTO
   *
   * @return OK
   */
  updateArbitreUsingPUT(params: PilotageControllerService.UpdateArbitreUsingPUTParams): __Observable<ArbitreDTO> {
    return this.updateArbitreUsingPUTResponse(params).pipe(
      __map(_r => _r.body as ArbitreDTO)
    );
  }

  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteArbitreUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<ArbitreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/arbitres/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArbitreDTO>;
      })
    );
  }
  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteArbitreUsingDELETE(id: string): __Observable<ArbitreDTO> {
    return this.deleteArbitreUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as ArbitreDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchArbitreUsingPATCHParams` containing the following parameters:
   *
   * - `id`: La clé de l'objet
   *
   * - `arbitreDTO`: arbitreDTO
   *
   * @return OK
   */
  patchArbitreUsingPATCHResponse(params: PilotageControllerService.PatchArbitreUsingPATCHParams): __Observable<__StrictHttpResponse<ArbitreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.arbitreDTO;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/arbitres/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArbitreDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchArbitreUsingPATCHParams` containing the following parameters:
   *
   * - `id`: La clé de l'objet
   *
   * - `arbitreDTO`: arbitreDTO
   *
   * @return OK
   */
  patchArbitreUsingPATCH(params: PilotageControllerService.PatchArbitreUsingPATCHParams): __Observable<ArbitreDTO> {
    return this.patchArbitreUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as ArbitreDTO)
    );
  }

  /**
   * Obtenir toutes les arbitres actifs
   * @return Requête traitée avec succès
   */
  getAllArbitresActifUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ArbitreDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/arbitresActifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ArbitreDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les arbitres actifs
   * @return Requête traitée avec succès
   */
  getAllArbitresActifUsingGET(): __Observable<Array<ArbitreDTO>> {
    return this.getAllArbitresActifUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ArbitreDTO>)
    );
  }

  /**
   * Obtenir toutes les LocalSyndical
   * @return Requête traitée avec succès
   */
  getAllLocalSyndicalsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<LocalSyndicaleDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/localSyndicals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocalSyndicaleDto>>;
      })
    );
  }
  /**
   * Obtenir toutes les LocalSyndical
   * @return Requête traitée avec succès
   */
  getAllLocalSyndicalsUsingGET(): __Observable<Array<LocalSyndicaleDto>> {
    return this.getAllLocalSyndicalsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<LocalSyndicaleDto>)
    );
  }

  /**
   * Obtenir toutes les motifs
   * @return Requête traitée avec succès
   */
  getAllMotifsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<MotifDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/motifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MotifDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les motifs
   * @return Requête traitée avec succès
   */
  getAllMotifsUsingGET(): __Observable<Array<MotifDTO>> {
    return this.getAllMotifsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<MotifDTO>)
    );
  }

  /**
   * Ajouter un motif
   * @param motifDTO motifDTO
   * @return OK
   */
  ajouterMotifUsingPOSTResponse(motifDTO: MotifDTO): __Observable<__StrictHttpResponse<MotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = motifDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/motifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MotifDTO>;
      })
    );
  }
  /**
   * Ajouter un motif
   * @param motifDTO motifDTO
   * @return OK
   */
  ajouterMotifUsingPOST(motifDTO: MotifDTO): __Observable<MotifDTO> {
    return this.ajouterMotifUsingPOSTResponse(motifDTO).pipe(
      __map(_r => _r.body as MotifDTO)
    );
  }

  /**
   * Obtenir un motif
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getMotifUsingGETResponse(id: string): __Observable<__StrictHttpResponse<MotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/motifs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MotifDTO>;
      })
    );
  }
  /**
   * Obtenir un motif
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getMotifUsingGET(id: string): __Observable<MotifDTO> {
    return this.getMotifUsingGETResponse(id).pipe(
      __map(_r => _r.body as MotifDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateMotifUsingPUTParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateMotifUsingPUTResponse(params: PilotageControllerService.UpdateMotifUsingPUTParams): __Observable<__StrictHttpResponse<MotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.motifDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/motifs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MotifDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateMotifUsingPUTParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateMotifUsingPUT(params: PilotageControllerService.UpdateMotifUsingPUTParams): __Observable<MotifDTO> {
    return this.updateMotifUsingPUTResponse(params).pipe(
      __map(_r => _r.body as MotifDTO)
    );
  }

  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteMotifUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<MotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/motifs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MotifDTO>;
      })
    );
  }
  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteMotifUsingDELETE(id: string): __Observable<MotifDTO> {
    return this.deleteMotifUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as MotifDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchMotifUsingPATCHParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchMotifUsingPATCHResponse(params: PilotageControllerService.PatchMotifUsingPATCHParams): __Observable<__StrictHttpResponse<MotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.motifDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/motifs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MotifDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchMotifUsingPATCHParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchMotifUsingPATCH(params: PilotageControllerService.PatchMotifUsingPATCHParams): __Observable<MotifDTO> {
    return this.patchMotifUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as MotifDTO)
    );
  }

  /**
   * Obtenir toutes les param application
   * @return Requête traitée avec succès
   */
  getAllParamApplicationUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ParamApplicationDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/paramApplications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ParamApplicationDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les param application
   * @return Requête traitée avec succès
   */
  getAllParamApplicationUsingGET(): __Observable<Array<ParamApplicationDTO>> {
    return this.getAllParamApplicationUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ParamApplicationDTO>)
    );
  }

  /**
   * Ajouter une param
   * @param paramApplcation paramApplcation
   * @return OK
   */
  ajouterUsingPOSTResponse(paramApplcation: ParamApplicationDTO): __Observable<__StrictHttpResponse<ParamApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paramApplcation;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/paramApplications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParamApplicationDTO>;
      })
    );
  }
  /**
   * Ajouter une param
   * @param paramApplcation paramApplcation
   * @return OK
   */
  ajouterUsingPOST(paramApplcation: ParamApplicationDTO): __Observable<ParamApplicationDTO> {
    return this.ajouterUsingPOSTResponse(paramApplcation).pipe(
      __map(_r => _r.body as ParamApplicationDTO)
    );
  }

  /**
   * Obtenir une paramApplication
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getExempleUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ParamApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/paramApplications/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParamApplicationDTO>;
      })
    );
  }
  /**
   * Obtenir une paramApplication
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getExempleUsingGET(id: string): __Observable<ParamApplicationDTO> {
    return this.getExempleUsingGETResponse(id).pipe(
      __map(_r => _r.body as ParamApplicationDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateUsingPUTParams` containing the following parameters:
   *
   * - `paramApplcation`: paramApplcation
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateUsingPUTResponse(params: PilotageControllerService.UpdateUsingPUTParams): __Observable<__StrictHttpResponse<ParamApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.paramApplcation;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/paramApplications/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParamApplicationDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateUsingPUTParams` containing the following parameters:
   *
   * - `paramApplcation`: paramApplcation
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateUsingPUT(params: PilotageControllerService.UpdateUsingPUTParams): __Observable<ParamApplicationDTO> {
    return this.updateUsingPUTResponse(params).pipe(
      __map(_r => _r.body as ParamApplicationDTO)
    );
  }

  /**
   * Supprimer une paramApplication
   * @param id La clé
   * @return OK
   */
  deleteExempleUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<ParamApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/paramApplications/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParamApplicationDTO>;
      })
    );
  }
  /**
   * Supprimer une paramApplication
   * @param id La clé
   * @return OK
   */
  deleteExempleUsingDELETE(id: string): __Observable<ParamApplicationDTO> {
    return this.deleteExempleUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as ParamApplicationDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchUsingPATCHParams` containing the following parameters:
   *
   * - `paramApplcation`: paramApplcation
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchUsingPATCHResponse(params: PilotageControllerService.PatchUsingPATCHParams): __Observable<__StrictHttpResponse<ParamApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.paramApplcation;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/paramApplications/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParamApplicationDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchUsingPATCHParams` containing the following parameters:
   *
   * - `paramApplcation`: paramApplcation
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchUsingPATCH(params: PilotageControllerService.PatchUsingPATCHParams): __Observable<ParamApplicationDTO> {
    return this.patchUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as ParamApplicationDTO)
    );
  }

  /**
   * Obtenir toutes les RegionSyndical
   * @return Requête traitée avec succès
   */
  getPatronalNextSequenceUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/patronalNextSequence`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Obtenir toutes les RegionSyndical
   * @return Requête traitée avec succès
   */
  getPatronalNextSequenceUsingGET(): __Observable<string> {
    return this.getPatronalNextSequenceUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Obtenir toutes les procureurPatronal
   * @return Requête traitée avec succès
   */
  getAllProcureurPatronalsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ProcureurPatronalDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProcureurPatronalDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les procureurPatronal
   * @return Requête traitée avec succès
   */
  getAllProcureurPatronalsUsingGET(): __Observable<Array<ProcureurPatronalDTO>> {
    return this.getAllProcureurPatronalsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ProcureurPatronalDTO>)
    );
  }

  /**
   * Ajouter un V
   * @param procureurPatronalDTO procureurPatronalDTO
   * @return OK
   */
  ajouterProcureurPatronalUsingPOSTResponse(procureurPatronalDTO: ProcureurPatronalDTO): __Observable<__StrictHttpResponse<ProcureurPatronalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = procureurPatronalDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurPatronalDTO>;
      })
    );
  }
  /**
   * Ajouter un V
   * @param procureurPatronalDTO procureurPatronalDTO
   * @return OK
   */
  ajouterProcureurPatronalUsingPOST(procureurPatronalDTO: ProcureurPatronalDTO): __Observable<ProcureurPatronalDTO> {
    return this.ajouterProcureurPatronalUsingPOSTResponse(procureurPatronalDTO).pipe(
      __map(_r => _r.body as ProcureurPatronalDTO)
    );
  }

  /**
   * Obtenir un ProcureurPatronal
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getProcureurPatronalUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ProcureurPatronalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurPatronalDTO>;
      })
    );
  }
  /**
   * Obtenir un ProcureurPatronal
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getProcureurPatronalUsingGET(id: string): __Observable<ProcureurPatronalDTO> {
    return this.getProcureurPatronalUsingGETResponse(id).pipe(
      __map(_r => _r.body as ProcureurPatronalDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateProcureurPatronalUsingPUTParams` containing the following parameters:
   *
   * - `procureurPatronalDTO`: procureurPatronalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateProcureurPatronalUsingPUTResponse(params: PilotageControllerService.UpdateProcureurPatronalUsingPUTParams): __Observable<__StrictHttpResponse<ProcureurPatronalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.procureurPatronalDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurPatronalDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateProcureurPatronalUsingPUTParams` containing the following parameters:
   *
   * - `procureurPatronalDTO`: procureurPatronalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateProcureurPatronalUsingPUT(params: PilotageControllerService.UpdateProcureurPatronalUsingPUTParams): __Observable<ProcureurPatronalDTO> {
    return this.updateProcureurPatronalUsingPUTResponse(params).pipe(
      __map(_r => _r.body as ProcureurPatronalDTO)
    );
  }

  /**
   * Supprimer une ProcureurPatronal
   * @param id La clé
   * @return OK
   */
  deleteProcureurPatronalUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<ProcureurPatronalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurPatronalDTO>;
      })
    );
  }
  /**
   * Supprimer une ProcureurPatronal
   * @param id La clé
   * @return OK
   */
  deleteProcureurPatronalUsingDELETE(id: string): __Observable<ProcureurPatronalDTO> {
    return this.deleteProcureurPatronalUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as ProcureurPatronalDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchProcureurPatronalUsingPATCHParams` containing the following parameters:
   *
   * - `procureurPatronalDTO`: procureurPatronalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchProcureurPatronalUsingPATCHResponse(params: PilotageControllerService.PatchProcureurPatronalUsingPATCHParams): __Observable<__StrictHttpResponse<ProcureurPatronalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.procureurPatronalDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/procureurPatronals/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurPatronalDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchProcureurPatronalUsingPATCHParams` containing the following parameters:
   *
   * - `procureurPatronalDTO`: procureurPatronalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchProcureurPatronalUsingPATCH(params: PilotageControllerService.PatchProcureurPatronalUsingPATCHParams): __Observable<ProcureurPatronalDTO> {
    return this.patchProcureurPatronalUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as ProcureurPatronalDTO)
    );
  }

  /**
   * Obtenir toutes les procureurPatronal actifs
   * @return Requête traitée avec succès
   */
  getAllProcureurPatronalsActifUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ProcureurPatronalDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurPatronalsActifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProcureurPatronalDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les procureurPatronal actifs
   * @return Requête traitée avec succès
   */
  getAllProcureurPatronalsActifUsingGET(): __Observable<Array<ProcureurPatronalDTO>> {
    return this.getAllProcureurPatronalsActifUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ProcureurPatronalDTO>)
    );
  }

  /**
   * Obtenir toutes les procureurSyndical
   * @return Requête traitée avec succès
   */
  getAllProcureurSyndicalsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ProcureurSyndicalDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProcureurSyndicalDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les procureurSyndical
   * @return Requête traitée avec succès
   */
  getAllProcureurSyndicalsUsingGET(): __Observable<Array<ProcureurSyndicalDTO>> {
    return this.getAllProcureurSyndicalsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ProcureurSyndicalDTO>)
    );
  }

  /**
   * Ajouter un V
   * @param procureurSyndicalDTO procureurSyndicalDTO
   * @return OK
   */
  ajouterProcureurSyndicalUsingPOSTResponse(procureurSyndicalDTO: ProcureurSyndicalDTO): __Observable<__StrictHttpResponse<ProcureurSyndicalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = procureurSyndicalDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurSyndicalDTO>;
      })
    );
  }
  /**
   * Ajouter un V
   * @param procureurSyndicalDTO procureurSyndicalDTO
   * @return OK
   */
  ajouterProcureurSyndicalUsingPOST(procureurSyndicalDTO: ProcureurSyndicalDTO): __Observable<ProcureurSyndicalDTO> {
    return this.ajouterProcureurSyndicalUsingPOSTResponse(procureurSyndicalDTO).pipe(
      __map(_r => _r.body as ProcureurSyndicalDTO)
    );
  }

  /**
   * Obtenir un ProcureurSyndical
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getProcureurSyndicalUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ProcureurSyndicalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurSyndicalDTO>;
      })
    );
  }
  /**
   * Obtenir un ProcureurSyndical
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getProcureurSyndicalUsingGET(id: string): __Observable<ProcureurSyndicalDTO> {
    return this.getProcureurSyndicalUsingGETResponse(id).pipe(
      __map(_r => _r.body as ProcureurSyndicalDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateProcureurSyndicalUsingPUTParams` containing the following parameters:
   *
   * - `procureurSyndicalDTO`: procureurSyndicalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateProcureurSyndicalUsingPUTResponse(params: PilotageControllerService.UpdateProcureurSyndicalUsingPUTParams): __Observable<__StrictHttpResponse<ProcureurSyndicalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.procureurSyndicalDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurSyndicalDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateProcureurSyndicalUsingPUTParams` containing the following parameters:
   *
   * - `procureurSyndicalDTO`: procureurSyndicalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateProcureurSyndicalUsingPUT(params: PilotageControllerService.UpdateProcureurSyndicalUsingPUTParams): __Observable<ProcureurSyndicalDTO> {
    return this.updateProcureurSyndicalUsingPUTResponse(params).pipe(
      __map(_r => _r.body as ProcureurSyndicalDTO)
    );
  }

  /**
   * Supprimer une ProcureurSyndical
   * @param id La clé
   * @return OK
   */
  deleteProcureurSyndicalUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<ProcureurSyndicalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurSyndicalDTO>;
      })
    );
  }
  /**
   * Supprimer une ProcureurSyndical
   * @param id La clé
   * @return OK
   */
  deleteProcureurSyndicalUsingDELETE(id: string): __Observable<ProcureurSyndicalDTO> {
    return this.deleteProcureurSyndicalUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as ProcureurSyndicalDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchProcureurSyndicalUsingPATCHParams` containing the following parameters:
   *
   * - `procureurSyndicalDTO`: procureurSyndicalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchProcureurSyndicalUsingPATCHResponse(params: PilotageControllerService.PatchProcureurSyndicalUsingPATCHParams): __Observable<__StrictHttpResponse<ProcureurSyndicalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.procureurSyndicalDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicals/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcureurSyndicalDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchProcureurSyndicalUsingPATCHParams` containing the following parameters:
   *
   * - `procureurSyndicalDTO`: procureurSyndicalDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchProcureurSyndicalUsingPATCH(params: PilotageControllerService.PatchProcureurSyndicalUsingPATCHParams): __Observable<ProcureurSyndicalDTO> {
    return this.patchProcureurSyndicalUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as ProcureurSyndicalDTO)
    );
  }

  /**
   * Obtenir toutes les procureurSyndical actifs
   * @return Requête traitée avec succès
   */
  getAllProcureurSyndicalsActifUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ProcureurSyndicalDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/procureurSyndicalsActifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProcureurSyndicalDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les procureurSyndical actifs
   * @return Requête traitée avec succès
   */
  getAllProcureurSyndicalsActifUsingGET(): __Observable<Array<ProcureurSyndicalDTO>> {
    return this.getAllProcureurSyndicalsActifUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ProcureurSyndicalDTO>)
    );
  }

  /**
   * Obtenir toutes les RegionSyndical
   * @return Requête traitée avec succès
   */
  getAllRegionSyndicalsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<RegionSyndicaleDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/regionSyndicals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RegionSyndicaleDto>>;
      })
    );
  }
  /**
   * Obtenir toutes les RegionSyndical
   * @return Requête traitée avec succès
   */
  getAllRegionSyndicalsUsingGET(): __Observable<Array<RegionSyndicaleDto>> {
    return this.getAllRegionSyndicalsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<RegionSyndicaleDto>)
    );
  }

  /**
   * Obtenir toutes les RegionSyndicales associees a une locale syndicale
   * @param id La clé de l'objet
   * @return Requête traitée avec succès
   */
  getRegionSyndicalesReferencesUsingGETResponse(id: string): __Observable<__StrictHttpResponse<Array<RegionSyndicaleDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/regionSyndicals/localSyndicalReference/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RegionSyndicaleDto>>;
      })
    );
  }
  /**
   * Obtenir toutes les RegionSyndicales associees a une locale syndicale
   * @param id La clé de l'objet
   * @return Requête traitée avec succès
   */
  getRegionSyndicalesReferencesUsingGET(id: string): __Observable<Array<RegionSyndicaleDto>> {
    return this.getRegionSyndicalesReferencesUsingGETResponse(id).pipe(
      __map(_r => _r.body as Array<RegionSyndicaleDto>)
    );
  }

  /**
   * Obtenir toutes les responsableRHs
   * @return Requête traitée avec succès
   */
  getAllResponsableRHsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ResponsableRHDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders(
      /* {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*'
      } */
    );
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/responsableRHs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResponsableRHDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les responsableRHs
   * @return Requête traitée avec succès
   */
  getAllResponsableRHsUsingGET(): __Observable<Array<ResponsableRHDTO>> {
    return this.getAllResponsableRHsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ResponsableRHDTO>)
    );
  }

  /**
   * Ajouter un V
   * @param responsableRHDTO responsableRHDTO
   * @return OK
   */
  ajouterResponsableRHUsingPOSTResponse(responsableRHDTO: ResponsableRHDTO): __Observable<__StrictHttpResponse<ResponsableRHDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = responsableRHDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/responsableRHs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponsableRHDTO>;
      })
    );
  }
  /**
   * Ajouter un V
   * @param responsableRHDTO responsableRHDTO
   * @return OK
   */
  ajouterResponsableRHUsingPOST(responsableRHDTO: ResponsableRHDTO): __Observable<ResponsableRHDTO> {
    return this.ajouterResponsableRHUsingPOSTResponse(responsableRHDTO).pipe(
      __map(_r => _r.body as ResponsableRHDTO)
    );
  }

  /**
   * Obtenir un responsableRHs
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getResponsableRHUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ResponsableRHDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/responsableRHs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponsableRHDTO>;
      })
    );
  }
  /**
   * Obtenir un responsableRHs
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getResponsableRHUsingGET(id: string): __Observable<ResponsableRHDTO> {
    return this.getResponsableRHUsingGETResponse(id).pipe(
      __map(_r => _r.body as ResponsableRHDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateResponsableRHUsingPUTParams` containing the following parameters:
   *
   * - `responsableRHDTO`: responsableRHDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateResponsableRHUsingPUTResponse(params: PilotageControllerService.UpdateResponsableRHUsingPUTParams): __Observable<__StrictHttpResponse<ResponsableRHDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.responsableRHDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/responsableRHs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponsableRHDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateResponsableRHUsingPUTParams` containing the following parameters:
   *
   * - `responsableRHDTO`: responsableRHDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateResponsableRHUsingPUT(params: PilotageControllerService.UpdateResponsableRHUsingPUTParams): __Observable<ResponsableRHDTO> {
    return this.updateResponsableRHUsingPUTResponse(params).pipe(
      __map(_r => _r.body as ResponsableRHDTO)
    );
  }

  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteResponsableRHUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<ResponsableRHDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/responsableRHs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponsableRHDTO>;
      })
    );
  }
  /**
   * Supprimer une motif
   * @param id La clé
   * @return OK
   */
  deleteResponsableRHUsingDELETE(id: string): __Observable<ResponsableRHDTO> {
    return this.deleteResponsableRHUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as ResponsableRHDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchResponsableRHUsingPATCHParams` containing the following parameters:
   *
   * - `responsableRHDTO`: responsableRHDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchResponsableRHUsingPATCHResponse(params: PilotageControllerService.PatchResponsableRHUsingPATCHParams): __Observable<__StrictHttpResponse<ResponsableRHDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.responsableRHDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/responsableRHs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponsableRHDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchResponsableRHUsingPATCHParams` containing the following parameters:
   *
   * - `responsableRHDTO`: responsableRHDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchResponsableRHUsingPATCH(params: PilotageControllerService.PatchResponsableRHUsingPATCHParams): __Observable<ResponsableRHDTO> {
    return this.patchResponsableRHUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as ResponsableRHDTO)
    );
  }

  /**
   * Obtenir toutes les sousMotifs
   * @return Requête traitée avec succès
   */
  getAllSousMotifsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<SousMotifDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/sousMotifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SousMotifDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les sousMotifs
   * @return Requête traitée avec succès
   */
  getAllSousMotifsUsingGET(): __Observable<Array<SousMotifDTO>> {
    return this.getAllSousMotifsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<SousMotifDTO>)
    );
  }

  /**
   * Ajouter un sousmotif
   * @param sousMotifDTO sousMotifDTO
   * @return OK
   */
  ajouterSousMotifUsingPOSTResponse(sousMotifDTO: SousMotifDTO): __Observable<__StrictHttpResponse<SousMotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = sousMotifDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/sousMotifs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SousMotifDTO>;
      })
    );
  }
  /**
   * Ajouter un sousmotif
   * @param sousMotifDTO sousMotifDTO
   * @return OK
   */
  ajouterSousMotifUsingPOST(sousMotifDTO: SousMotifDTO): __Observable<SousMotifDTO> {
    return this.ajouterSousMotifUsingPOSTResponse(sousMotifDTO).pipe(
      __map(_r => _r.body as SousMotifDTO)
    );
  }

  /**
   * Obtenir un sousmotif
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getSousMotifUsingGETResponse(id: string): __Observable<__StrictHttpResponse<SousMotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/sousMotifs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SousMotifDTO>;
      })
    );
  }
  /**
   * Obtenir un sousmotif
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getSousMotifUsingGET(id: string): __Observable<SousMotifDTO> {
    return this.getSousMotifUsingGETResponse(id).pipe(
      __map(_r => _r.body as SousMotifDTO)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateSousMotifUsingPUTParams` containing the following parameters:
   *
   * - `sousMotifDTO`: sousMotifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateSousMotifUsingPUTResponse(params: PilotageControllerService.UpdateSousMotifUsingPUTParams): __Observable<__StrictHttpResponse<SousMotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.sousMotifDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/sousMotifs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SousMotifDTO>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateSousMotifUsingPUTParams` containing the following parameters:
   *
   * - `sousMotifDTO`: sousMotifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateSousMotifUsingPUT(params: PilotageControllerService.UpdateSousMotifUsingPUTParams): __Observable<SousMotifDTO> {
    return this.updateSousMotifUsingPUTResponse(params).pipe(
      __map(_r => _r.body as SousMotifDTO)
    );
  }

  /**
   * Supprimer une souys motif
   * @param id La clé
   * @return OK
   */
  deleteSousMotifUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<SousMotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/sousMotifs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SousMotifDTO>;
      })
    );
  }
  /**
   * Supprimer une souys motif
   * @param id La clé
   * @return OK
   */
  deleteSousMotifUsingDELETE(id: string): __Observable<SousMotifDTO> {
    return this.deleteSousMotifUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as SousMotifDTO)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchSousMotifUsingPATCHParams` containing the following parameters:
   *
   * - `sousMotifDTO`: sousMotifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchSousMotifUsingPATCHResponse(params: PilotageControllerService.PatchSousMotifUsingPATCHParams): __Observable<__StrictHttpResponse<SousMotifDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.sousMotifDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/sousMotifs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SousMotifDTO>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchSousMotifUsingPATCHParams` containing the following parameters:
   *
   * - `sousMotifDTO`: sousMotifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchSousMotifUsingPATCH(params: PilotageControllerService.PatchSousMotifUsingPATCHParams): __Observable<SousMotifDTO> {
    return this.patchSousMotifUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as SousMotifDTO)
    );
  }

  /**
   * Obtenir toutes les TypePieceJointe
   * @return Requête traitée avec succès
   */
  getAllTypePieceJointesUsingGETResponse(): __Observable<__StrictHttpResponse<Array<TypePieceJointeDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders(
      /* {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': '*'
      } */
    );
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TypePieceJointeDto>>;
      })
    );
  }
  /**
   * Obtenir toutes les TypePieceJointe
   * @return Requête traitée avec succès
   */
  getAllTypePieceJointesUsingGET(): __Observable<Array<TypePieceJointeDto>> {
    return this.getAllTypePieceJointesUsingGETResponse().pipe(
      __map(_r => _r.body as Array<TypePieceJointeDto>)
    );
  }

  /**
   * Ajouter un typePieceJointe
   * @param typePieceJointeDto typePieceJointeDto
   * @return OK
   */
  ajouterTypePieceJointeUsingPOSTResponse(typePieceJointeDto: TypePieceJointeDto): __Observable<__StrictHttpResponse<TypePieceJointeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders(
      /*  {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Methods': '*'
       } */
    );
    let __body: any = null;
    __body = typePieceJointeDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypePieceJointeDto>;
      })
    );
  }
  /**
   * Ajouter un typePieceJointe
   * @param typePieceJointeDto typePieceJointeDto
   * @return OK
   */
  ajouterTypePieceJointeUsingPOST(typePieceJointeDto: TypePieceJointeDto): __Observable<TypePieceJointeDto> {
    return this.ajouterTypePieceJointeUsingPOSTResponse(typePieceJointeDto).pipe(
      __map(_r => _r.body as TypePieceJointeDto)
    );
  }

  /**
   * Obtenir un typePieceJointe
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getTypePieceJointeUsingGETResponse(id: string): __Observable<__StrictHttpResponse<TypePieceJointeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypePieceJointeDto>;
      })
    );
  }
  /**
   * Obtenir un typePieceJointe
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getTypePieceJointeUsingGET(id: string): __Observable<TypePieceJointeDto> {
    return this.getTypePieceJointeUsingGETResponse(id).pipe(
      __map(_r => _r.body as TypePieceJointeDto)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateTypePieceJointeUsingPUTParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateTypePieceJointeUsingPUTResponse(params: PilotageControllerService.UpdateTypePieceJointeUsingPUTParams): __Observable<__StrictHttpResponse<TypePieceJointeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.motifDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypePieceJointeDto>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateTypePieceJointeUsingPUTParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateTypePieceJointeUsingPUT(params: PilotageControllerService.UpdateTypePieceJointeUsingPUTParams): __Observable<TypePieceJointeDto> {
    return this.updateTypePieceJointeUsingPUTResponse(params).pipe(
      __map(_r => _r.body as TypePieceJointeDto)
    );
  }

  /**
   * Supprimer une typePieceJointe
   * @param id La clé
   * @return OK
   */
  deleteTypePieceJointeUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<TypePieceJointeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypePieceJointeDto>;
      })
    );
  }
  /**
   * Supprimer une typePieceJointe
   * @param id La clé
   * @return OK
   */
  deleteTypePieceJointeUsingDELETE(id: string): __Observable<TypePieceJointeDto> {
    return this.deleteTypePieceJointeUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as TypePieceJointeDto)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchTypePieceJointeUsingPATCHParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchTypePieceJointeUsingPATCHResponse(params: PilotageControllerService.PatchTypePieceJointeUsingPATCHParams): __Observable<__StrictHttpResponse<TypePieceJointeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.motifDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/typePieceJointes/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypePieceJointeDto>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchTypePieceJointeUsingPATCHParams` containing the following parameters:
   *
   * - `motifDTO`: motifDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchTypePieceJointeUsingPATCH(params: PilotageControllerService.PatchTypePieceJointeUsingPATCHParams): __Observable<TypePieceJointeDto> {
    return this.patchTypePieceJointeUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as TypePieceJointeDto)
    );
  }

  /**
   * Obtenir toutes les utilisateurs
   * @return Requête traitée avec succès
   */
  getAllUtilisateursUsingGETResponse(): __Observable<__StrictHttpResponse<Array<UtilisateurDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/utilisateurs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UtilisateurDTO>>;
      })
    );
  }
  /**
   * Obtenir toutes les utilisateurs
   * @return Requête traitée avec succès
   */
  getAllUtilisateursUsingGET(): __Observable<Array<UtilisateurDTO>> {
    return this.getAllUtilisateursUsingGETResponse().pipe(
      __map(_r => _r.body as Array<UtilisateurDTO>)
    );
  }

  /**
   * Ajouter un utilisateur
   * @param sousMotifDTO sousMotifDTO
   * @return OK
   */
  ajouterUtilisateurUsingPOSTResponse(sousMotifDTO: SousMotifDTO): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = sousMotifDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/pilotage/utilisateurs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Ajouter un utilisateur
   * @param sousMotifDTO sousMotifDTO
   * @return OK
   */
  ajouterUtilisateurUsingPOST(sousMotifDTO: SousMotifDTO): __Observable<string> {
    return this.ajouterUtilisateurUsingPOSTResponse(sousMotifDTO).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Obtenir un utilisateur
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getUtilisateurUsingGETResponse(id: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/pilotage/utilisateurs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Obtenir un utilisateur
   * @param id La clé
   * @return Requête traitée avec succès
   */
  getUtilisateurUsingGET(id: string): __Observable<string> {
    return this.getUtilisateurUsingGETResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateUtilisateurUsingPUTParams` containing the following parameters:
   *
   * - `utilisateurDTO`: utilisateurDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateUtilisateurUsingPUTResponse(params: PilotageControllerService.UpdateUtilisateurUsingPUTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.utilisateurDTO;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/pilotage/utilisateurs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Modifier l'objet pour id
   * @param params The `PilotageControllerService.UpdateUtilisateurUsingPUTParams` containing the following parameters:
   *
   * - `utilisateurDTO`: utilisateurDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  updateUtilisateurUsingPUT(params: PilotageControllerService.UpdateUtilisateurUsingPUTParams): __Observable<string> {
    return this.updateUtilisateurUsingPUTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Supprimer une utilisateur
   * @param id La clé
   * @return OK
   */
  deleteUtilisateurfUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/pilotage/utilisateurs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Supprimer une utilisateur
   * @param id La clé
   * @return OK
   */
  deleteUtilisateurfUsingDELETE(id: string): __Observable<string> {
    return this.deleteUtilisateurfUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchUtilisateurUsingPATCHParams` containing the following parameters:
   *
   * - `utilisateurDTO`: utilisateurDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchUtilisateurUsingPATCHResponse(params: PilotageControllerService.PatchUtilisateurUsingPATCHParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.utilisateurDTO;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/pilotage/utilisateurs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Patcher l'objet pour id
   * @param params The `PilotageControllerService.PatchUtilisateurUsingPATCHParams` containing the following parameters:
   *
   * - `utilisateurDTO`: utilisateurDTO
   *
   * - `id`: La clé de l'objet
   *
   * @return OK
   */
  patchUtilisateurUsingPATCH(params: PilotageControllerService.PatchUtilisateurUsingPATCHParams): __Observable<string> {
    return this.patchUtilisateurUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module PilotageControllerService {

  /**
   * Parameters for updateArbitreUsingPUT
   */
  export interface UpdateArbitreUsingPUTParams {

    /**
     * La clé de l'objet
     */
    id: string;

    /**
     * arbitreDTO
     */
    arbitreDTO: ArbitreDTO;
  }

  /**
   * Parameters for patchArbitreUsingPATCH
   */
  export interface PatchArbitreUsingPATCHParams {

    /**
     * La clé de l'objet
     */
    id: string;

    /**
     * arbitreDTO
     */
    arbitreDTO: ArbitreDTO;
  }

  /**
   * Parameters for updateMotifUsingPUT
   */
  export interface UpdateMotifUsingPUTParams {

    /**
     * motifDTO
     */
    motifDTO: MotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchMotifUsingPATCH
   */
  export interface PatchMotifUsingPATCHParams {

    /**
     * motifDTO
     */
    motifDTO: MotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateUsingPUT
   */
  export interface UpdateUsingPUTParams {

    /**
     * paramApplcation
     */
    paramApplcation: ParamApplicationDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchUsingPATCH
   */
  export interface PatchUsingPATCHParams {

    /**
     * paramApplcation
     */
    paramApplcation: ParamApplicationDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateProcureurPatronalUsingPUT
   */
  export interface UpdateProcureurPatronalUsingPUTParams {

    /**
     * procureurPatronalDTO
     */
    procureurPatronalDTO: ProcureurPatronalDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchProcureurPatronalUsingPATCH
   */
  export interface PatchProcureurPatronalUsingPATCHParams {

    /**
     * procureurPatronalDTO
     */
    procureurPatronalDTO: ProcureurPatronalDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateProcureurSyndicalUsingPUT
   */
  export interface UpdateProcureurSyndicalUsingPUTParams {

    /**
     * procureurSyndicalDTO
     */
    procureurSyndicalDTO: ProcureurSyndicalDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchProcureurSyndicalUsingPATCH
   */
  export interface PatchProcureurSyndicalUsingPATCHParams {

    /**
     * procureurSyndicalDTO
     */
    procureurSyndicalDTO: ProcureurSyndicalDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateResponsableRHUsingPUT
   */
  export interface UpdateResponsableRHUsingPUTParams {

    /**
     * responsableRHDTO
     */
    responsableRHDTO: ResponsableRHDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchResponsableRHUsingPATCH
   */
  export interface PatchResponsableRHUsingPATCHParams {

    /**
     * responsableRHDTO
     */
    responsableRHDTO: ResponsableRHDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateSousMotifUsingPUT
   */
  export interface UpdateSousMotifUsingPUTParams {

    /**
     * sousMotifDTO
     */
    sousMotifDTO: SousMotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchSousMotifUsingPATCH
   */
  export interface PatchSousMotifUsingPATCHParams {

    /**
     * sousMotifDTO
     */
    sousMotifDTO: SousMotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateTypePieceJointeUsingPUT
   */
  export interface UpdateTypePieceJointeUsingPUTParams {

    /**
     * motifDTO
     */
    motifDTO: MotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchTypePieceJointeUsingPATCH
   */
  export interface PatchTypePieceJointeUsingPATCHParams {

    /**
     * motifDTO
     */
    motifDTO: MotifDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for updateUtilisateurUsingPUT
   */
  export interface UpdateUtilisateurUsingPUTParams {

    /**
     * utilisateurDTO
     */
    utilisateurDTO: UtilisateurDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }

  /**
   * Parameters for patchUtilisateurUsingPATCH
   */
  export interface PatchUtilisateurUsingPATCHParams {

    /**
     * utilisateurDTO
     */
    utilisateurDTO: UtilisateurDTO;

    /**
     * La clé de l'objet
     */
    id: string;
  }
}

export { PilotageControllerService }
