import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks, DoInit, LoadingBehavior } from '@ngxhq/common-ui';
import { OpenIdUserInfo, OpenIdService } from '@ngxhq/security';
import { InformationDTO } from '../core/api/client/models/information-dto';
import { InfoService, PilotageControllerService } from '../core/api/client/services';
import { PilotageMainService } from '../services/pilotagemain.service';

@Component({
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HomeComponent extends ComponentBase implements DoInit {
    public userFullname: string;
    public grievanceInfos: any[] = [];
    public infoApp: InformationDTO;
    public visibleLoading: boolean = false;

    constructor(
        public _el: ElementRef,
        public _renderer2: Renderer2,
        public _openIdService: OpenIdService,
        public _infoService: InfoService,
        public _pilotageControllerService: PilotageControllerService,
        public _pilotageMainService: PilotageMainService,
    ) {
        super();
    }

    @LoadingBehavior()
    async doInit() {
        (sessionStorage.getItem('localSyndicals') === null) ? this.visibleLoading = true : this.visibleLoading = false;

        (sessionStorage.getItem('infosUser') === null) ?
            sessionStorage.setItem('infosUser', JSON.stringify(await this._infoService.getInformationLoginUsingGET().toPromise())) : null;

        this.infoApp = await this._infoService.getInformationUsingGET().toPromise();

        this._renderer2.addClass(this._el.nativeElement.ownerDocument.body, 'fullscreen-background');
        this.userFullname = this._getUserFullName(await this._openIdService.getUserInfo());

        await this._initServices();
    }

    /**
     * @returns Promise
     */
    public async _initServices(): Promise<void> {
        if (
            sessionStorage.getItem('responsableRH') === null
            || sessionStorage.getItem('responsableRH') === undefined
            || sessionStorage.getItem('responsableRH') === 'undefined') {
            this._pilotageMainService.dataResponsableRH$.subscribe((data: any) => {
                sessionStorage.setItem('responsableRH', JSON.stringify(data));
            }, (err: any) => console.log(err));
        }

        (sessionStorage.getItem('localSyndicals') === null) ?
            sessionStorage.setItem('localSyndicals', JSON.stringify(await this._pilotageControllerService.getAllLocalSyndicalsUsingGET().toPromise())) : null;

        (sessionStorage.getItem('regionSyndicales') === null) ?
            sessionStorage.setItem('regionSyndicales', JSON.stringify(await this._pilotageControllerService.getAllRegionSyndicalsUsingGET().toPromise())) : null;

        (sessionStorage.getItem('motif') === null) ?
            sessionStorage.setItem('motif', JSON.stringify(await this._pilotageControllerService.getAllMotifsUsingGET().toPromise())) : null;

        (sessionStorage.getItem('sousMotif') === null) ?
            sessionStorage.setItem('sousMotif', JSON.stringify(await this._pilotageControllerService.getAllSousMotifsUsingGET().toPromise())) : null;

        (sessionStorage.getItem('procureurSyndicals') === null) ?
            sessionStorage.setItem('procureurSyndicals', JSON.stringify(await this._pilotageControllerService.getAllProcureurSyndicalsUsingGET().toPromise())) : null;

        this.visibleLoading = false; //close dialog splashscreen

        await this._initLazyLoadingServices();
    }

    /**
     * @returns Promise
     */
    public async _initLazyLoadingServices(): Promise<void> {
        (sessionStorage.getItem('typePieceJointes') === null
            || sessionStorage.getItem('typePieceJointes') === undefined
            || sessionStorage.getItem('typePieceJointes') === 'undefined') ?
            sessionStorage.setItem('typePieceJointes', JSON.stringify(await this._pilotageControllerService.getAllTypePieceJointesUsingGET().toPromise())) : null;
    }

    /**
     * Commentaire pour _getUserFullName
     * Obtenir le nom complet de l'utilisateur connecté
     * @param  {OpenIdUserInfo|undefined} userInfo
     * @returns string
     */
    public _getUserFullName(userInfo: OpenIdUserInfo | undefined): string {
        let name: string = (userInfo) ? userInfo.firstName : '';

        if (userInfo && !(/^[A-Za-z]{3}/.test(userInfo.id))) {
            name = userInfo.id.split('@')[0];
        }

        return name;
    }
}
