/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PageResultDossierDTO } from '../models/page-result-dossier-dto';
import { RechercheDossierLegerDto } from '../models/recherche-dossier-leger-dto';
import { PageResultDossierLegerDTO } from '../models/page-result-dossier-leger-dto';
import { ActionAnalyseDTO } from '../models/action-analyse-dto';
import { DossierDTO } from '../models/dossier-dto';
import { FiltreDTO } from '../models/filtre-dto';
import { CalendrierDTO } from '../models/calendrier-dto';
import { GabaritDetail } from '../models/gabarit-detail';
import { PieceJointeDTO } from '../models/piece-jointe-dto';
import { DossierLegerDTO } from '../models/dossier-leger-dto';
import { DetailsUniteStructurelleDTO } from '../models/details-unite-structurelle-dto';
import { GriefWebRecherche } from '../models/grief-web-recherche';
import { TransfertGriefsDTO } from '../models/transfert-griefs-dto';
import { TransfertGriefUniteStructurelleDTO } from '../models/transfert-grief-unite-structurelle-dto';

/**
 * Dossier Grief Controller
 */
@Injectable({
  providedIn: 'root',
})
class GriefControllerService extends __BaseService {
  static readonly billeterieUsingGETPath = '/api/v1/griefs/Billetterie';
  static readonly listeActifsUsingPOSTPath = '/api/v1/griefs/actifs/{numeroPage}';
  static readonly listeActifsLegerUsingPOSTPath = '/api/v1/griefs/actifsLeger/{numeroPage}';
  static readonly getActionAnalyseUsingGETPath = '/api/v1/griefs/actionAnalyses';
  static readonly creerAdminUsingPOSTPath = '/api/v1/griefs/adminCreer';
  static readonly ajouterDocGenUsingPOSTPath = '/api/v1/griefs/ajouterDocGen';
  static readonly ajouterEtapeProtocoleUsingGETPath = '/api/v1/griefs/ajouterEtapeProtocole';
  static readonly ajouterFichierConversionUsingPOSTPath = '/api/v1/griefs/ajouterFichierConversion';
  static readonly ajouterFiltreUsingPOSTPath = '/api/v1/griefs/ajouterFiltre';
  static readonly listeArbitragesUsingPOSTPath = '/api/v1/griefs/arbitrages/{numeroPage}';
  static readonly listeArbitragesLegerUsingPOSTPath = '/api/v1/griefs/arbitragesLeger/{numeroPage}';
  static readonly accessPlatformCosignoUsingGETPath = '/api/v1/griefs/batchSignature';
  static readonly calendrierEnAttenteArbitragesUsingGETPath = '/api/v1/griefs/calendrier';
  static readonly listeDossierEnArbitragesUsingGETPath = '/api/v1/griefs/calendrier/arbitrages';
  static readonly listGabaritsUsingGETPath = '/api/v1/griefs/checkGabarits';
  static readonly creerUsingPOSTPath = '/api/v1/griefs/creer';
  static readonly genererDocumentUsingGETPath = '/api/v1/griefs/genererDocument';
  static readonly lanceSignatureUsingGETPath = '/api/v1/griefs/lanceSignature/{numeroGrief}';
  static readonly listGabaritsParTypeUsingGETPath = '/api/v1/griefs/listGabarits';
  static readonly listS3UsingGETPath = '/api/v1/griefs/listS3';
  static readonly listeDocGenUsingGETPath = '/api/v1/griefs/listeDocGen';
  static readonly listeFiltreUsingGETPath = '/api/v1/griefs/listeFiltre';
  static readonly listeParResponsableRHUsingGETPath = '/api/v1/griefs/listeParResponsableRH';
  static readonly listeParUniteStructurelleExistanteUsingGETPath = '/api/v1/griefs/listeParUniteStructurelleExistante';
  static readonly listeParUniteStructurelleNonExistanteUsingGETPath = '/api/v1/griefs/listeParUniteStructurelleNonExistante';
  static readonly loadDossiersUsingGETPath = '/api/v1/griefs/load';
  static readonly modifierSyndicUsingPOSTPath = '/api/v1/griefs/modifier';
  static readonly nettoyagePGPUsingGETPath = '/api/v1/griefs/nettoyagePGP';
  static readonly listeNouveauxUsingPOSTPath = '/api/v1/griefs/nouveaux/{numeroPage}';
  static readonly listeNouveauxLegerUsingPOSTPath = '/api/v1/griefs/nouveauxLeger/{numeroPage}';
  static readonly listeOrganisationnelsUsingPOSTPath = '/api/v1/griefs/organisationnels/{numeroPage}';
  static readonly listeOrganisationnelsLegerUsingPOSTPath = '/api/v1/griefs/organisationnelsLeger/{numeroPage}';
  static readonly listePersonnelsUsingPOSTPath = '/api/v1/griefs/personnels/{numeroPage}';
  static readonly listePersonnelsLegerUsingPOSTPath = '/api/v1/griefs/personnelsLeger/{numeroPage}';
  static readonly rapportAgeGriefUsingGETPath = '/api/v1/griefs/rapport/ageGrief';
  static readonly griefArbitragesBackUsingGETPath = '/api/v1/griefs/rapport/griefArbitragesBack';
  static readonly griefArbitragesFrontUsingGETPath = '/api/v1/griefs/rapport/griefArbitragesFront';
  static readonly rapportProtocoleUsingGETPath = '/api/v1/griefs/rapport/protocole';
  static readonly rapportExcelUtilisateurUsingPOSTPath = '/api/v1/griefs/rapportExcelUtilisateur';
  static readonly rapportExcelUtilisateurLocalBisUsingPOSTPath = '/api/v1/griefs/rapportExcelUtilisateurFront';
  static readonly rechercheUsingPOSTPath = '/api/v1/griefs/rechercheDossiers';
  static readonly rechercheAvecFiltreUsingPOSTPath = '/api/v1/griefs/rechercheDossiersAvecFiltre';
  static readonly rechercheAvecFiltreLegerUsingPOSTPath = '/api/v1/griefs/rechercheDossiersAvecFiltreLeger';
  static readonly rechercheLegerUsingPOSTPath = '/api/v1/griefs/rechercheDossiersLeger';
  static readonly senEmailAjoutDateArbitrageUsingGETPath = '/api/v1/griefs/sendEmailADA/{numeroGrief}';
  static readonly senEmailARAUsingGETPath = '/api/v1/griefs/sendEmailARA/{numeroGrief}';
  static readonly senEmailARSUsingGETPath = '/api/v1/griefs/sendEmailARS/{numeroGrief}';
  static readonly senEmailCourrielNotificationConclusionUsingGETPath = '/api/v1/griefs/sendEmailCon/{numeroGrief}';
  static readonly senEmailModifDateArbitrageUsingGETPath = '/api/v1/griefs/sendEmailMDA/{numeroGrief}';
  static readonly senEmailModifResponsableRHUsingGETPath = '/api/v1/griefs/sendEmailMRH/{numeroGrief}';
  static readonly setCountThreadsUsingGETPath = '/api/v1/griefs/setCountThreads';
  static readonly setExcelIncrementUsingGETPath = '/api/v1/griefs/setExcelIncrement';
  static readonly setExcelThresholdUsingGETPath = '/api/v1/griefs/setExcelThreshold';
  static readonly setFixedThreadsUsingGETPath = '/api/v1/griefs/setFixedThreads';
  static readonly shutdownUsingGETPath = '/api/v1/griefs/shutdown';
  static readonly approuverUsingPOSTPath = '/api/v1/griefs/supprimer';
  static readonly deleteFiltreUsingDELETEPath = '/api/v1/griefs/supprimerFiltre/{id}';
  static readonly listeTousUsingPOSTPath = '/api/v1/griefs/tous/{numeroPage}';
  static readonly listeTousLegerUsingPOSTPath = '/api/v1/griefs/tousLeger/{numeroPage}';
  static readonly listeTousLegerNouveauxInclusUsingPOSTPath = '/api/v1/griefs/tousLegerNouveauxInclus/{numeroPage}';
  static readonly transfererGriefsEntreResponsableRHUsingPOSTPath = '/api/v1/griefs/transfererGriefsEntreResponsableRH';
  static readonly transfererGriefsEntreUnitesStructurellesUsingPOSTPath = '/api/v1/griefs/transfererGriefsEntreUnitesStructurelles';
  static readonly getGriefUniqueUsingGETPath = '/api/v1/griefs/uniqueness/{numeroGrief}';
  static readonly afficherDocGeneralUsingGETPath = '/api/v1/griefs/{idDocGeneral}/afficherDocGeneral';
  static readonly supprimerDocGenUsingDELETEPath = '/api/v1/griefs/{idDocGeneral}/supprimerDocGen';
  static readonly afficherPieceJointeFromGriefUsingGETPath = '/api/v1/griefs/{idGrief}/afficherPieceJointe';
  static readonly afficherPieceJointeFromGriefDecomUsingGETPath = '/api/v1/griefs/{idGrief}/afficherPieceJointeDecom';
  static readonly listePieceJointeUsingGETPath = '/api/v1/griefs/{idGrief}/listePieceJointe';
  static readonly ajouterPieceJointeUsingPOSTPath = '/api/v1/griefs/{idGrief}/piecejointe';
  static readonly deletePieceJointeUsingDELETEPath = '/api/v1/griefs/{idGrief}/supprimerPiecejointe/{idPieceJointe}';
  static readonly getGriefUsingGETPath = '/api/v1/griefs/{numeroGrief}';
  static readonly exportRechercheDossiersPOSTPath = '/api/v1/griefs/exportRechercheDossiers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Billetterie
   */
  billeterieUsingGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/Billetterie`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Billetterie
   */
  billeterieUsingGET(): __Observable<null> {
    return this.billeterieUsingGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * atteindre une page des dossiers actifs
   * @param params The `GriefControllerService.ListeActifsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeActifsUsingPOSTResponse(params: GriefControllerService.ListeActifsUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/actifs/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers actifs
   * @param params The `GriefControllerService.ListeActifsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeActifsUsingPOST(params: GriefControllerService.ListeActifsUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listeActifsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page des dossiers actifs
   * @param params The `GriefControllerService.ListeActifsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeActifsLegerUsingPOSTResponse(params: GriefControllerService.ListeActifsLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/actifsLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers actifs
   * @param params The `GriefControllerService.ListeActifsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeActifsLegerUsingPOST(params: GriefControllerService.ListeActifsLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeActifsLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * retrouver les ActionAnalyse
   * @param params The `GriefControllerService.GetActionAnalyseUsingGETParams` containing the following parameters:
   *
   * - `typeGrief`: Type de grief
   *
   * - `sousMotifId`: Id de sous motif
   *
   * - `localSyndicalId`: Id du local syndical
   *
   * - `dateDepot`: La date de depot
   *
   * @return OK
   */
  getActionAnalyseUsingGETResponse(params: GriefControllerService.GetActionAnalyseUsingGETParams): __Observable<__StrictHttpResponse<Array<ActionAnalyseDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.typeGrief != null) __params = __params.set('typeGrief', params.typeGrief.toString());
    if (params.sousMotifId != null) __params = __params.set('sousMotifId', params.sousMotifId.toString());
    if (params.localSyndicalId != null) __params = __params.set('localSyndicalId', params.localSyndicalId.toString());
    if (params.dateDepot != null) __params = __params.set('dateDepot', params.dateDepot.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/actionAnalyses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActionAnalyseDTO>>;
      })
    );
  }
  /**
   * retrouver les ActionAnalyse
   * @param params The `GriefControllerService.GetActionAnalyseUsingGETParams` containing the following parameters:
   *
   * - `typeGrief`: Type de grief
   *
   * - `sousMotifId`: Id de sous motif
   *
   * - `localSyndicalId`: Id du local syndical
   *
   * - `dateDepot`: La date de depot
   *
   * @return OK
   */
  getActionAnalyseUsingGET(params: GriefControllerService.GetActionAnalyseUsingGETParams): __Observable<Array<ActionAnalyseDTO>> {
    return this.getActionAnalyseUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ActionAnalyseDTO>)
    );
  }

  /**
   * Créer des dossiers de griefs en Admin
   * @param dto Le grief a créer
   */
  creerAdminUsingPOSTResponse(dto?: DossierDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/adminCreer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Créer des dossiers de griefs en Admin
   * @param dto Le grief a créer
   */
  creerAdminUsingPOST(dto?: DossierDTO): __Observable<null> {
    return this.creerAdminUsingPOSTResponse(dto).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * ajouter un document général
   * @param params The `GriefControllerService.AjouterDocGenUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `pieceJointeDTO`: pieceJointeDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterDocGenUsingPOSTResponse(params: GriefControllerService.AjouterDocGenUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob); }
    if (params.pieceJointeDTO != null) { __formData.append('pieceJointeDTO', params.pieceJointeDTO as string | Blob); }
    if (params.forceAdd != null) { __formData.append('forceAdd', JSON.stringify(params.forceAdd)); }
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/ajouterDocGen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ajouter un document général
   * @param params The `GriefControllerService.AjouterDocGenUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `pieceJointeDTO`: pieceJointeDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterDocGenUsingPOST(params: GriefControllerService.AjouterDocGenUsingPOSTParams): __Observable<string> {
    return this.ajouterDocGenUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * ajouterEtapeProtocole
   * @return OK
   */
  ajouterEtapeProtocoleUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/ajouterEtapeProtocole`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ajouterEtapeProtocole
   * @return OK
   */
  ajouterEtapeProtocoleUsingGET(): __Observable<string> {
    return this.ajouterEtapeProtocoleUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * ajouterFichierConversion
   * @param params The `GriefControllerService.AjouterFichierConversionUsingPOSTParams` containing the following parameters:
   *
   * - `fileName`: fileName
   *
   * - `file`: file
   *
   * @return OK
   */
  ajouterFichierConversionUsingPOSTResponse(params: GriefControllerService.AjouterFichierConversionUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.fileName != null) { __formData.append('fileName', params.fileName as string | Blob); }
    if (params.file != null) { __formData.append('file', params.file as string | Blob); }
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/ajouterFichierConversion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ajouterFichierConversion
   * @param params The `GriefControllerService.AjouterFichierConversionUsingPOSTParams` containing the following parameters:
   *
   * - `fileName`: fileName
   *
   * - `file`: file
   *
   * @return OK
   */
  ajouterFichierConversionUsingPOST(params: GriefControllerService.AjouterFichierConversionUsingPOSTParams): __Observable<string> {
    return this.ajouterFichierConversionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * ajouterFiltre
   * @param params The `GriefControllerService.AjouterFiltreUsingPOSTParams` containing the following parameters:
   *
   * - `filtreDTO`: filtreDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterFiltreUsingPOSTResponse(params: GriefControllerService.AjouterFiltreUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.filtreDTO;
    if (params.forceAdd != null) __params = __params.set('forceAdd', params.forceAdd.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/ajouterFiltre`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ajouterFiltre
   * @param params The `GriefControllerService.AjouterFiltreUsingPOSTParams` containing the following parameters:
   *
   * - `filtreDTO`: filtreDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterFiltreUsingPOST(params: GriefControllerService.AjouterFiltreUsingPOSTParams): __Observable<string> {
    return this.ajouterFiltreUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * atteindre une page des dossiers prochainement en arbitrage
   * @param params The `GriefControllerService.ListeArbitragesUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeArbitragesUsingPOSTResponse(params: GriefControllerService.ListeArbitragesUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/arbitrages/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers prochainement en arbitrage
   * @param params The `GriefControllerService.ListeArbitragesUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeArbitragesUsingPOST(params: GriefControllerService.ListeArbitragesUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listeArbitragesUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page des dossiers prochainement en arbitrage
   * @param params The `GriefControllerService.ListeArbitragesLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeArbitragesLegerUsingPOSTResponse(params: GriefControllerService.ListeArbitragesLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/arbitragesLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers prochainement en arbitrage
   * @param params The `GriefControllerService.ListeArbitragesLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeArbitragesLegerUsingPOST(params: GriefControllerService.ListeArbitragesLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeArbitragesLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * batchSignature
   */
  accessPlatformCosignoUsingGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/batchSignature`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * batchSignature
   */
  accessPlatformCosignoUsingGET(): __Observable<null> {
    return this.accessPlatformCosignoUsingGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Calendrier
   * @param params The `GriefControllerService.CalendrierEnAttenteArbitragesUsingGETParams` containing the following parameters:
   *
   * - `mois`: mois
   *
   * - `annee`: annee
   *
   * @return OK
   */
  calendrierEnAttenteArbitragesUsingGETResponse(params: GriefControllerService.CalendrierEnAttenteArbitragesUsingGETParams): __Observable<__StrictHttpResponse<Array<CalendrierDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.mois != null) __params = __params.set('mois', params.mois.toString());
    if (params.annee != null) __params = __params.set('annee', params.annee.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/calendrier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CalendrierDTO>>;
      })
    );
  }
  /**
   * Calendrier
   * @param params The `GriefControllerService.CalendrierEnAttenteArbitragesUsingGETParams` containing the following parameters:
   *
   * - `mois`: mois
   *
   * - `annee`: annee
   *
   * @return OK
   */
  calendrierEnAttenteArbitragesUsingGET(params: GriefControllerService.CalendrierEnAttenteArbitragesUsingGETParams): __Observable<Array<CalendrierDTO>> {
    return this.calendrierEnAttenteArbitragesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<CalendrierDTO>)
    );
  }

  /**
   * atteindre une page des dossiers prochainement en arbitrage pour une jounée
   * @param params The `GriefControllerService.ListeDossierEnArbitragesUsingGETParams` containing the following parameters:
   *
   * - `numeroPage`: numero de Page
   *
   * - `date`: date arbitrage
   *
   * @return OK
   */
  listeDossierEnArbitragesUsingGETResponse(params: GriefControllerService.ListeDossierEnArbitragesUsingGETParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numeroPage != null) __params = __params.set('numeroPage', params.numeroPage.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/calendrier/arbitrages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers prochainement en arbitrage pour une jounée
   * @param params The `GriefControllerService.ListeDossierEnArbitragesUsingGETParams` containing the following parameters:
   *
   * - `numeroPage`: numero de Page
   *
   * - `date`: date arbitrage
   *
   * @return OK
   */
  listeDossierEnArbitragesUsingGET(params: GriefControllerService.ListeDossierEnArbitragesUsingGETParams): __Observable<PageResultDossierDTO> {
    return this.listeDossierEnArbitragesUsingGETResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * checkGabarits
   * @return OK
   */
  listGabaritsUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/checkGabarits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * checkGabarits
   * @return OK
   */
  listGabaritsUsingGET(): __Observable<string> {
    return this.listGabaritsUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Créer des dossiers de griefs en Syndicat
   * @param dto Le grief a créer
   * @return OK
   */
  creerUsingPOSTResponse(dto?: DossierDTO): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/creer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Créer des dossiers de griefs en Syndicat
   * @param dto Le grief a créer
   * @return OK
   */
  creerUsingPOST(dto?: DossierDTO): __Observable<string> {
    return this.creerUsingPOSTResponse(dto).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * afficher un document général
   * @param params The `GriefControllerService.GenererDocumentUsingGETParams` containing the following parameters:
   *
   * - `numeroGrief`: numéro de grief
   *
   * - `idGabarit`: idGabarit
   *
   * @return OK
   */
  genererDocumentUsingGETResponse(params: GriefControllerService.GenererDocumentUsingGETParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numeroGrief != null) __params = __params.set('numeroGrief', params.numeroGrief.toString());
    if (params.idGabarit != null) __params = __params.set('idGabarit', params.idGabarit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/genererDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * afficher un document général
   * @param params The `GriefControllerService.GenererDocumentUsingGETParams` containing the following parameters:
   *
   * - `numeroGrief`: numéro de grief
   *
   * - `idGabarit`: idGabarit
   *
   * @return OK
   */
  genererDocumentUsingGET(params: GriefControllerService.GenererDocumentUsingGETParams): __Observable<ArrayBuffer> {
    return this.genererDocumentUsingGETResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * lanceSignature
   * @param numeroGrief numeroGrief
   * @return OK
   */
  lanceSignatureUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/lanceSignature/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * lanceSignature
   * @param numeroGrief numeroGrief
   * @return OK
   */
  lanceSignatureUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.lanceSignatureUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * listGabarits
   * @param typeGrief type de grief
   * @return OK
   */
  listGabaritsParTypeUsingGETResponse(typeGrief?: 'IND' | 'COL' | 'PAT' | 'GEN'): __Observable<__StrictHttpResponse<Array<GabaritDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (typeGrief != null) __params = __params.set('typeGrief', typeGrief.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listGabarits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GabaritDetail>>;
      })
    );
  }
  /**
   * listGabarits
   * @param typeGrief type de grief
   * @return OK
   */
  listGabaritsParTypeUsingGET(typeGrief?: 'IND' | 'COL' | 'PAT' | 'GEN'): __Observable<Array<GabaritDetail>> {
    return this.listGabaritsParTypeUsingGETResponse(typeGrief).pipe(
      __map(_r => _r.body as Array<GabaritDetail>)
    );
  }

  /**
   * loader des dossiers
   * @return OK
   */
  listS3UsingGETResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listS3`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * loader des dossiers
   * @return OK
   */
  listS3UsingGET(): __Observable<Array<string>> {
    return this.listS3UsingGETResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * liste des documents généraux
   * @return OK
   */
  listeDocGenUsingGETResponse(): __Observable<__StrictHttpResponse<Array<PieceJointeDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listeDocGen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PieceJointeDTO>>;
      })
    );
  }
  /**
   * liste des documents généraux
   * @return OK
   */
  listeDocGenUsingGET(): __Observable<Array<PieceJointeDTO>> {
    return this.listeDocGenUsingGETResponse().pipe(
      __map(_r => _r.body as Array<PieceJointeDTO>)
    );
  }

  /**
   * liste des filtres
   * @return OK
   */
  listeFiltreUsingGETResponse(): __Observable<__StrictHttpResponse<Array<FiltreDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listeFiltre`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FiltreDTO>>;
      })
    );
  }
  /**
   * liste des filtres
   * @return OK
   */
  listeFiltreUsingGET(): __Observable<Array<FiltreDTO>> {
    return this.listeFiltreUsingGETResponse().pipe(
      __map(_r => _r.body as Array<FiltreDTO>)
    );
  }

  /**
   * Envoie la liste des griefs reliés au responsable RH sélectionné
   * @param idResponsableRH Le ID du responsable RH
   * @return OK
   */
  listeParResponsableRHUsingGETResponse(idResponsableRH?: string): __Observable<__StrictHttpResponse<Array<DossierLegerDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idResponsableRH != null) __params = __params.set('idResponsableRH', idResponsableRH.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listeParResponsableRH`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DossierLegerDTO>>;
      })
    );
  }
  /**
   * Envoie la liste des griefs reliés au responsable RH sélectionné
   * @param idResponsableRH Le ID du responsable RH
   * @return OK
   */
  listeParResponsableRHUsingGET(idResponsableRH?: string): __Observable<Array<DossierLegerDTO>> {
    return this.listeParResponsableRHUsingGETResponse(idResponsableRH).pipe(
      __map(_r => _r.body as Array<DossierLegerDTO>)
    );
  }

  /**
   * Envoie la liste unités structurelles qui existent encore
   * @return OK
   */
  listeParUniteStructurelleExistanteUsingGETResponse(): __Observable<__StrictHttpResponse<Array<DetailsUniteStructurelleDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listeParUniteStructurelleExistante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DetailsUniteStructurelleDTO>>;
      })
    );
  }
  /**
   * Envoie la liste unités structurelles qui existent encore
   * @return OK
   */
  listeParUniteStructurelleExistanteUsingGET(): __Observable<Array<DetailsUniteStructurelleDTO>> {
    return this.listeParUniteStructurelleExistanteUsingGETResponse().pipe(
      __map(_r => _r.body as Array<DetailsUniteStructurelleDTO>)
    );
  }

  /**
   * Envoie la liste unités structurelles qui n'existent plus
   * @return OK
   */
  listeParUniteStructurelleNonExistanteUsingGETResponse(): __Observable<__StrictHttpResponse<Array<DetailsUniteStructurelleDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/listeParUniteStructurelleNonExistante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DetailsUniteStructurelleDTO>>;
      })
    );
  }
  /**
   * Envoie la liste unités structurelles qui n'existent plus
   * @return OK
   */
  listeParUniteStructurelleNonExistanteUsingGET(): __Observable<Array<DetailsUniteStructurelleDTO>> {
    return this.listeParUniteStructurelleNonExistanteUsingGETResponse().pipe(
      __map(_r => _r.body as Array<DetailsUniteStructurelleDTO>)
    );
  }

  /**
   * loader des dossiers
   * @return OK
   */
  loadDossiersUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/load`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loader des dossiers
   * @return OK
   */
  loadDossiersUsingGET(): __Observable<string> {
    return this.loadDossiersUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Modifier grief en Syndicat
   * @param dto Le grief a créer
   */
  modifierSyndicUsingPOSTResponse(dto?: DossierDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/modifier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Modifier grief en Syndicat
   * @param dto Le grief a créer
   */
  modifierSyndicUsingPOST(dto?: DossierDTO): __Observable<null> {
    return this.modifierSyndicUsingPOSTResponse(dto).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * nettoyagePGP
   */
  nettoyagePGPUsingGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/nettoyagePGP`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * nettoyagePGP
   */
  nettoyagePGPUsingGET(): __Observable<null> {
    return this.nettoyagePGPUsingGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * atteindre une page des nouveaux dossiers
   * @param params The `GriefControllerService.ListeNouveauxUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeNouveauxUsingPOSTResponse(params: GriefControllerService.ListeNouveauxUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/nouveaux/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des nouveaux dossiers
   * @param params The `GriefControllerService.ListeNouveauxUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeNouveauxUsingPOST(params: GriefControllerService.ListeNouveauxUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listeNouveauxUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page des nouveaux dossiers
   * @param params The `GriefControllerService.ListeNouveauxLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeNouveauxLegerUsingPOSTResponse(params: GriefControllerService.ListeNouveauxLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/nouveauxLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page des nouveaux dossiers
   * @param params The `GriefControllerService.ListeNouveauxLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeNouveauxLegerUsingPOST(params: GriefControllerService.ListeNouveauxLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeNouveauxLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * atteindre une page des dossiers organisationnels
   * @param params The `GriefControllerService.ListeOrganisationnelsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeOrganisationnelsUsingPOSTResponse(params: GriefControllerService.ListeOrganisationnelsUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/organisationnels/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers organisationnels
   * @param params The `GriefControllerService.ListeOrganisationnelsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeOrganisationnelsUsingPOST(params: GriefControllerService.ListeOrganisationnelsUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listeOrganisationnelsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page des dossiers organisationnels
   * @param params The `GriefControllerService.ListeOrganisationnelsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeOrganisationnelsLegerUsingPOSTResponse(params: GriefControllerService.ListeOrganisationnelsLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/organisationnelsLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers organisationnels
   * @param params The `GriefControllerService.ListeOrganisationnelsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeOrganisationnelsLegerUsingPOST(params: GriefControllerService.ListeOrganisationnelsLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeOrganisationnelsLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * atteindre une page des dossiers personnels
   * @param params The `GriefControllerService.ListePersonnelsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listePersonnelsUsingPOSTResponse(params: GriefControllerService.ListePersonnelsUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/personnels/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers personnels
   * @param params The `GriefControllerService.ListePersonnelsUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listePersonnelsUsingPOST(params: GriefControllerService.ListePersonnelsUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listePersonnelsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page des dossiers personnels
   * @param params The `GriefControllerService.ListePersonnelsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listePersonnelsLegerUsingPOSTResponse(params: GriefControllerService.ListePersonnelsLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/personnelsLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page des dossiers personnels
   * @param params The `GriefControllerService.ListePersonnelsLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listePersonnelsLegerUsingPOST(params: GriefControllerService.ListePersonnelsLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listePersonnelsLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * Rapport AgeGrief
   * @return OK
   */
  rapportAgeGriefUsingGETResponse(): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/rapport/ageGrief`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * Rapport AgeGrief
   * @return OK
   */
  rapportAgeGriefUsingGET(): __Observable<ArrayBuffer> {
    return this.rapportAgeGriefUsingGETResponse().pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * Rapport en Arbitrage en Back
   * @param params The `GriefControllerService.GriefArbitragesBackUsingGETParams` containing the following parameters:
   *
   * - `toYear`: toYear
   *
   * - `toMonth`: toMonth
   *
   * - `fromYear`: fromYear
   *
   * - `fromMonth`: fromMonth
   *
   * - `format`: format
   *
   * @return OK
   */
  griefArbitragesBackUsingGETResponse(params: GriefControllerService.GriefArbitragesBackUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toYear != null) __params = __params.set('toYear', params.toYear.toString());
    if (params.toMonth != null) __params = __params.set('toMonth', params.toMonth.toString());
    if (params.fromYear != null) __params = __params.set('fromYear', params.fromYear.toString());
    if (params.fromMonth != null) __params = __params.set('fromMonth', params.fromMonth.toString());
    if (params.format != null) __params = __params.set('format', params.format.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/rapport/griefArbitragesBack`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Rapport en Arbitrage en Back
   * @param params The `GriefControllerService.GriefArbitragesBackUsingGETParams` containing the following parameters:
   *
   * - `toYear`: toYear
   *
   * - `toMonth`: toMonth
   *
   * - `fromYear`: fromYear
   *
   * - `fromMonth`: fromMonth
   *
   * - `format`: format
   *
   * @return OK
   */
  griefArbitragesBackUsingGET(params: GriefControllerService.GriefArbitragesBackUsingGETParams): __Observable<string> {
    return this.griefArbitragesBackUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Rapport en Arbitrage en Front
   * @param params The `GriefControllerService.GriefArbitragesFrontUsingGETParams` containing the following parameters:
   *
   * - `toYear`: toYear
   *
   * - `toMonth`: toMonth
   *
   * - `fromYear`: fromYear
   *
   * - `fromMonth`: fromMonth
   *
   * - `format`: format
   *
   * @return OK
   */
  griefArbitragesFrontUsingGETResponse(params: GriefControllerService.GriefArbitragesFrontUsingGETParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toYear != null) __params = __params.set('toYear', params.toYear.toString());
    if (params.toMonth != null) __params = __params.set('toMonth', params.toMonth.toString());
    if (params.fromYear != null) __params = __params.set('fromYear', params.fromYear.toString());
    if (params.fromMonth != null) __params = __params.set('fromMonth', params.fromMonth.toString());
    if (params.format != null) __params = __params.set('format', params.format.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/rapport/griefArbitragesFront`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * Rapport en Arbitrage en Front
   * @param params The `GriefControllerService.GriefArbitragesFrontUsingGETParams` containing the following parameters:
   *
   * - `toYear`: toYear
   *
   * - `toMonth`: toMonth
   *
   * - `fromYear`: fromYear
   *
   * - `fromMonth`: fromMonth
   *
   * - `format`: format
   *
   * @return OK
   */
  griefArbitragesFrontUsingGET(params: GriefControllerService.GriefArbitragesFrontUsingGETParams): __Observable<ArrayBuffer> {
    return this.griefArbitragesFrontUsingGETResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * Rapport Protocole
   * @param annee annee
   * @return OK
   */
  rapportProtocoleUsingGETResponse(annee?: string): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (annee != null) __params = __params.set('annee', annee.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/rapport/protocole`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * Rapport Protocole
   * @param annee annee
   * @return OK
   */
  rapportProtocoleUsingGET(annee?: string): __Observable<ArrayBuffer> {
    return this.rapportProtocoleUsingGETResponse(annee).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.RapportExcelUtilisateurUsingPOSTParams` containing the following parameters:
   *
   * - `categorie`: categorie
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  rapportExcelUtilisateurUsingPOSTResponse(params: GriefControllerService.RapportExcelUtilisateurUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.categorie != null) __params = __params.set('categorie', params.categorie.toString());
    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rapportExcelUtilisateur`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.RapportExcelUtilisateurUsingPOSTParams` containing the following parameters:
   *
   * - `categorie`: categorie
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  rapportExcelUtilisateurUsingPOST(params: GriefControllerService.RapportExcelUtilisateurUsingPOSTParams): __Observable<string> {
    return this.rapportExcelUtilisateurUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.RapportExcelUtilisateurLocalBisUsingPOSTParams` containing the following parameters:
   *
   * - `categorie`: categorie
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  rapportExcelUtilisateurLocalBisUsingPOSTResponse(params: GriefControllerService.RapportExcelUtilisateurLocalBisUsingPOSTParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.categorie != null) __params = __params.set('categorie', params.categorie.toString());
    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rapportExcelUtilisateurFront`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.RapportExcelUtilisateurLocalBisUsingPOSTParams` containing the following parameters:
   *
   * - `categorie`: categorie
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  rapportExcelUtilisateurLocalBisUsingPOST(params: GriefControllerService.RapportExcelUtilisateurLocalBisUsingPOSTParams): __Observable<ArrayBuffer> {
    return this.rapportExcelUtilisateurLocalBisUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * Recherche les griefs
   * @param casWebRecherche casWebRecherche
   * @return OK
   */
  rechercheUsingPOSTResponse(casWebRecherche: GriefWebRecherche): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = casWebRecherche;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rechercheDossiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * Recherche les griefs
   * @param casWebRecherche casWebRecherche
   * @return OK
   */
  rechercheUsingPOST(casWebRecherche: GriefWebRecherche): __Observable<PageResultDossierDTO> {
    return this.rechercheUsingPOSTResponse(casWebRecherche).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * Recherche les griefs
   * @param id id
   * @return OK
   */
  rechercheAvecFiltreUsingPOSTResponse(id: string): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = id;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rechercheDossiersAvecFiltre`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * Recherche les griefs
   * @param id id
   * @return OK
   */
  rechercheAvecFiltreUsingPOST(id: string): __Observable<PageResultDossierDTO> {
    return this.rechercheAvecFiltreUsingPOSTResponse(id).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * Recherche les griefs
   * @param id id
   * @return OK
   */
  rechercheAvecFiltreLegerUsingPOSTResponse(id: string): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = id;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rechercheDossiersAvecFiltreLeger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * Recherche les griefs
   * @param id id
   * @return OK
   */
  rechercheAvecFiltreLegerUsingPOST(id: string): __Observable<PageResultDossierLegerDTO> {
    return this.rechercheAvecFiltreLegerUsingPOSTResponse(id).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * Recherche les griefs
   * @param casWebRecherche casWebRecherche
   * @return OK
   */
  rechercheLegerUsingPOSTResponse(casWebRecherche: GriefWebRecherche): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = casWebRecherche;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/rechercheDossiersLeger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * Recherche les griefs
   * @param casWebRecherche casWebRecherche
   * @return OK
   */
  rechercheLegerUsingPOST(casWebRecherche: GriefWebRecherche): __Observable<PageResultDossierLegerDTO> {
    return this.rechercheLegerUsingPOSTResponse(casWebRecherche).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailAjoutDateArbitrageUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailADA/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailAjoutDateArbitrageUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailAjoutDateArbitrageUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailARAUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailARA/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailARAUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailARAUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailARSUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailARS/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailARSUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailARSUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailCourrielNotificationConclusionUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailCon/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailCourrielNotificationConclusionUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailCourrielNotificationConclusionUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailModifDateArbitrageUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailMDA/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailModifDateArbitrageUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailModifDateArbitrageUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailModifResponsableRHUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/sendEmailMRH/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * send un email
   * @param numeroGrief numeroGrief
   * @return OK
   */
  senEmailModifResponsableRHUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.senEmailModifResponsableRHUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
   * loader des dossiers
   * @param countThreads countThreads
   * @return OK
   */
  setCountThreadsUsingGETResponse(countThreads: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countThreads != null) __params = __params.set('countThreads', countThreads.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/setCountThreads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loader des dossiers
   * @param countThreads countThreads
   * @return OK
   */
  setCountThreadsUsingGET(countThreads: number): __Observable<string> {
    return this.setCountThreadsUsingGETResponse(countThreads).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loader des dossiers
   * @param excelIncrement excelIncrement
   * @return OK
   */
  setExcelIncrementUsingGETResponse(excelIncrement: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (excelIncrement != null) __params = __params.set('excelIncrement', excelIncrement.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/setExcelIncrement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loader des dossiers
   * @param excelIncrement excelIncrement
   * @return OK
   */
  setExcelIncrementUsingGET(excelIncrement: number): __Observable<string> {
    return this.setExcelIncrementUsingGETResponse(excelIncrement).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loader des dossiers
   * @param excelSeuil excelSeuil
   * @return OK
   */
  setExcelThresholdUsingGETResponse(excelSeuil: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (excelSeuil != null) __params = __params.set('excelSeuil', excelSeuil.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/setExcelThreshold`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loader des dossiers
   * @param excelSeuil excelSeuil
   * @return OK
   */
  setExcelThresholdUsingGET(excelSeuil: number): __Observable<string> {
    return this.setExcelThresholdUsingGETResponse(excelSeuil).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loader des dossiers
   * @param fixedThreads fixedThreads
   * @return OK
   */
  setFixedThreadsUsingGETResponse(fixedThreads: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (fixedThreads != null) __params = __params.set('fixedThreads', fixedThreads.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/setFixedThreads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loader des dossiers
   * @param fixedThreads fixedThreads
   * @return OK
   */
  setFixedThreadsUsingGET(fixedThreads: number): __Observable<string> {
    return this.setFixedThreadsUsingGETResponse(fixedThreads).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * shutdown
   */
  shutdownUsingGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/shutdown`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * shutdown
   */
  shutdownUsingGET(): __Observable<null> {
    return this.shutdownUsingGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Supprimer des dossiers de griefs
   * @param ids liste de id
   */
  approuverUsingPOSTResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/supprimer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * Supprimer des dossiers de griefs
   * @param ids liste de id
   */
  approuverUsingPOST(ids?: Array<string>): __Observable<null> {
    return this.approuverUsingPOSTResponse(ids).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Supprimer un filtre avec son id
   * @param id La clé
   * @return OK
   */
  deleteFiltreUsingDELETEResponse(id: string): __Observable<__StrictHttpResponse<FiltreDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/griefs/supprimerFiltre/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FiltreDTO>;
      })
    );
  }
  /**
   * Supprimer un filtre avec son id
   * @param id La clé
   * @return OK
   */
  deleteFiltreUsingDELETE(id: string): __Observable<FiltreDTO> {
    return this.deleteFiltreUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as FiltreDTO)
    );
  }

  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.ListeTousUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousUsingPOSTResponse(params: GriefControllerService.ListeTousUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/tous/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierDTO>;
      })
    );
  }
  /**
   * atteindre une page de tous les dossiers
   * @param params The `GriefControllerService.ListeTousUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousUsingPOST(params: GriefControllerService.ListeTousUsingPOSTParams): __Observable<PageResultDossierDTO> {
    return this.listeTousUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierDTO)
    );
  }

  /**
   * atteindre une page de tous les dossiers en recherhce
   * @param params The `GriefControllerService.ListeTousLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousLegerUsingPOSTResponse(params: GriefControllerService.ListeTousLegerUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/tousLeger/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page de tous les dossiers en recherhce
   * @param params The `GriefControllerService.ListeTousLegerUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousLegerUsingPOST(params: GriefControllerService.ListeTousLegerUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeTousLegerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * atteindre une page de tous les dossiers en recherhce incluant nouveaux
   * @param params The `GriefControllerService.ListeTousLegerNouveauxInclusUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousLegerNouveauxInclusUsingPOSTResponse(params: GriefControllerService.ListeTousLegerNouveauxInclusUsingPOSTParams): __Observable<__StrictHttpResponse<PageResultDossierLegerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.rechercheDossierLegerDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/tousLegerNouveauxInclus/${encodeURIComponent(String(params.numeroPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultDossierLegerDTO>;
      })
    );
  }
  /**
   * atteindre une page de tous les dossiers en recherhce incluant nouveaux
   * @param params The `GriefControllerService.ListeTousLegerNouveauxInclusUsingPOSTParams` containing the following parameters:
   *
   * - `numeroPage`: numeroPage
   *
   * - `rechercheDossierLegerDto`: liste de critere
   *
   * @return OK
   */
  listeTousLegerNouveauxInclusUsingPOST(params: GriefControllerService.ListeTousLegerNouveauxInclusUsingPOSTParams): __Observable<PageResultDossierLegerDTO> {
    return this.listeTousLegerNouveauxInclusUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as PageResultDossierLegerDTO)
    );
  }

  /**
   * Transfere les griefs choisis d'un responsable RH vers un autre responsable RH
   * @param transfertGriefsDTO liste de critere
   */
  transfererGriefsEntreResponsableRHUsingPOSTResponse(transfertGriefsDTO?: TransfertGriefsDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transfertGriefsDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/transfererGriefsEntreResponsableRH`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Transfere les griefs choisis d'un responsable RH vers un autre responsable RH
   * @param transfertGriefsDTO liste de critere
   */
  transfererGriefsEntreResponsableRHUsingPOST(transfertGriefsDTO?: TransfertGriefsDTO): __Observable<null> {
    return this.transfererGriefsEntreResponsableRHUsingPOSTResponse(transfertGriefsDTO).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Transfere les griefs d'une unité structurelle vers une autre unité
   * @param transfertGriefsUniteStructurelleDTO liste de TransfertGriefUniteStructurelleDTO
   */
  transfererGriefsEntreUnitesStructurellesUsingPOSTResponse(transfertGriefsUniteStructurelleDTO?: Array<TransfertGriefUniteStructurelleDTO>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transfertGriefsUniteStructurelleDTO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/transfererGriefsEntreUnitesStructurelles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Transfere les griefs d'une unité structurelle vers une autre unité
   * @param transfertGriefsUniteStructurelleDTO liste de TransfertGriefUniteStructurelleDTO
   */
  transfererGriefsEntreUnitesStructurellesUsingPOST(transfertGriefsUniteStructurelleDTO?: Array<TransfertGriefUniteStructurelleDTO>): __Observable<null> {
    return this.transfererGriefsEntreUnitesStructurellesUsingPOSTResponse(transfertGriefsUniteStructurelleDTO).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Lire un grief en particulier sans renvoyer d'erreur si absent
   * @param numeroGrief Le numéro de grief
   * @return OK
   */
  getGriefUniqueUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/uniqueness/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Lire un grief en particulier sans renvoyer d'erreur si absent
   * @param numeroGrief Le numéro de grief
   * @return OK
   */
  getGriefUniqueUsingGET(numeroGrief: string): __Observable<boolean> {
    return this.getGriefUniqueUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * afficher un document général
   * @param idDocGeneral idDocGeneral
   * @return OK
   */
  afficherDocGeneralUsingGETResponse(idDocGeneral: string): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(idDocGeneral))}/afficherDocGeneral/${encodeURIComponent(String(idDocGeneral))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * afficher un document général
   * @param idDocGeneral idDocGeneral
   * @return OK
   */
  afficherDocGeneralUsingGET(idDocGeneral: string): __Observable<ArrayBuffer> {
    return this.afficherDocGeneralUsingGETResponse(idDocGeneral).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * Supprimer un document général
   * @param idDocGeneral idDocGeneral
   * @return OK
   */
  supprimerDocGenUsingDELETEResponse(idDocGeneral: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(idDocGeneral))}/supprimerDocGen/${encodeURIComponent(String(idDocGeneral))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Supprimer un document général
   * @param idDocGeneral idDocGeneral
   * @return OK
   */
  supprimerDocGenUsingDELETE(idDocGeneral: string): __Observable<string> {
    return this.supprimerDocGenUsingDELETEResponse(idDocGeneral).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * afficherPieceJointeFromGrief
   * @param params The `GriefControllerService.AfficherPieceJointeFromGriefUsingGETParams` containing the following parameters:
   *
   * - `idPieceJointe`: idPieceJointe
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  afficherPieceJointeFromGriefUsingGETResponse(params: GriefControllerService.AfficherPieceJointeFromGriefUsingGETParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idPieceJointe != null) __params = __params.set('idPieceJointe', params.idPieceJointe.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(params.idGrief))}/afficherPieceJointe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * afficherPieceJointeFromGrief
   * @param params The `GriefControllerService.AfficherPieceJointeFromGriefUsingGETParams` containing the following parameters:
   *
   * - `idPieceJointe`: idPieceJointe
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  afficherPieceJointeFromGriefUsingGET(params: GriefControllerService.AfficherPieceJointeFromGriefUsingGETParams): __Observable<ArrayBuffer> {
    return this.afficherPieceJointeFromGriefUsingGETResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * afficherPieceJointeFromGriefDecom
   * @param params The `GriefControllerService.AfficherPieceJointeFromGriefDecomUsingGETParams` containing the following parameters:
   *
   * - `keyName`: keyName
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  afficherPieceJointeFromGriefDecomUsingGETResponse(params: GriefControllerService.AfficherPieceJointeFromGriefDecomUsingGETParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.keyName != null) __params = __params.set('keyName', params.keyName.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(params.idGrief))}/afficherPieceJointeDecom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * afficherPieceJointeFromGriefDecom
   * @param params The `GriefControllerService.AfficherPieceJointeFromGriefDecomUsingGETParams` containing the following parameters:
   *
   * - `keyName`: keyName
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  afficherPieceJointeFromGriefDecomUsingGET(params: GriefControllerService.AfficherPieceJointeFromGriefDecomUsingGETParams): __Observable<ArrayBuffer> {
    return this.afficherPieceJointeFromGriefDecomUsingGETResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * liste des pieces jointes
   * @param idGrief idGrief
   * @return OK
   */
  listePieceJointeUsingGETResponse(idGrief: string): __Observable<__StrictHttpResponse<Array<PieceJointeDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(idGrief))}/listePieceJointe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PieceJointeDTO>>;
      })
    );
  }
  /**
   * liste des pieces jointes
   * @param idGrief idGrief
   * @return OK
   */
  listePieceJointeUsingGET(idGrief: string): __Observable<Array<PieceJointeDTO>> {
    return this.listePieceJointeUsingGETResponse(idGrief).pipe(
      __map(_r => _r.body as Array<PieceJointeDTO>)
    );
  }

  /**
   * ajouterPieceJointe
   * @param params The `GriefControllerService.AjouterPieceJointeUsingPOSTParams` containing the following parameters:
   *
   * - `idGrief`: idGrief
   *
   * - `file`: file
   *
   * - `pieceJointeDTO`: pieceJointeDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterPieceJointeUsingPOSTResponse(params: GriefControllerService.AjouterPieceJointeUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob); }
    if (params.pieceJointeDTO != null) { __formData.append('pieceJointeDTO', params.pieceJointeDTO as string | Blob); }
    if (params.forceAdd != null) { __formData.append('forceAdd', JSON.stringify(params.forceAdd)); }
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(params.idGrief))}/piecejointe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * ajouterPieceJointe
   * @param params The `GriefControllerService.AjouterPieceJointeUsingPOSTParams` containing the following parameters:
   *
   * - `idGrief`: idGrief
   *
   * - `file`: file
   *
   * - `pieceJointeDTO`: pieceJointeDTO
   *
   * - `forceAdd`: forceAdd
   *
   * @return OK
   */
  ajouterPieceJointeUsingPOST(params: GriefControllerService.AjouterPieceJointeUsingPOSTParams): __Observable<string> {
    return this.ajouterPieceJointeUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Supprimer une piece jointe d'un dossier
   * @param params The `GriefControllerService.DeletePieceJointeUsingDELETEParams` containing the following parameters:
   *
   * - `keyName`: keyName
   *
   * - `idPieceJointe`: idPieceJointe
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  deletePieceJointeUsingDELETEResponse(params: GriefControllerService.DeletePieceJointeUsingDELETEParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.keyName != null) __params = __params.set('keyName', params.keyName.toString());


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/griefs/${encodeURIComponent(String(params.idGrief))}/supprimerPiecejointe/${encodeURIComponent(String(params.idPieceJointe))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Supprimer une piece jointe d'un dossier
   * @param params The `GriefControllerService.DeletePieceJointeUsingDELETEParams` containing the following parameters:
   *
   * - `keyName`: keyName
   *
   * - `idPieceJointe`: idPieceJointe
   *
   * - `idGrief`: idGrief
   *
   * @return OK
   */
  deletePieceJointeUsingDELETE(params: GriefControllerService.DeletePieceJointeUsingDELETEParams): __Observable<string> {
    return this.deletePieceJointeUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Lire un grief en particulier
   * @param numeroGrief Le numéro de grief
   * @return OK
   */
  getGriefUsingGETResponse(numeroGrief: string): __Observable<__StrictHttpResponse<DossierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/griefs/byNumeroGrief/${encodeURIComponent(String(numeroGrief))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DossierDTO>;
      })
    );
  }
  /**
   * Lire un grief en particulier
   * @param numeroGrief Le numéro de grief
   * @return OK
   */
  getGriefUsingGET(numeroGrief: string): __Observable<DossierDTO> {
    return this.getGriefUsingGETResponse(numeroGrief).pipe(
      __map(_r => _r.body as DossierDTO)
    );
  }

  /**
* Export des griefs selon critere de la recherche avancée
* @param casWebRecherche casWebRecherche
*/
  exportRechercheDossiersPOSTResponse(casWebRecherche: GriefWebRecherche): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = casWebRecherche;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/griefs/exportRechercheDossiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob' as 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }

  /**
 * Export des griefs selon critere de la recherche avancée
 * @param casWebRecherche casWebRecherche
 */
  exportRechercheDossiersPOST(casWebRecherche: GriefWebRecherche): __Observable<Blob> {
    return this.exportRechercheDossiersPOSTResponse(casWebRecherche).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module GriefControllerService {

  /**
   * Parameters for listeActifsUsingPOST
   */
  export interface ListeActifsUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeActifsLegerUsingPOST
   */
  export interface ListeActifsLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for getActionAnalyseUsingGET
   */
  export interface GetActionAnalyseUsingGETParams {

    /**
     * Type de grief
     */
    typeGrief?: string;

    /**
     * Id de sous motif
     */
    sousMotifId?: string;

    /**
     * Id du local syndical
     */
    localSyndicalId?: string;

    /**
     * La date de depot
     */
    dateDepot?: string;
  }

  /**
   * Parameters for ajouterDocGenUsingPOST
   */
  export interface AjouterDocGenUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * pieceJointeDTO
     */
    pieceJointeDTO?: string;

    /**
     * forceAdd
     */
    forceAdd?: boolean;
  }

  /**
   * Parameters for ajouterFichierConversionUsingPOST
   */
  export interface AjouterFichierConversionUsingPOSTParams {

    /**
     * fileName
     */
    fileName: string;

    /**
     * file
     */
    file: Blob;
  }

  /**
   * Parameters for ajouterFiltreUsingPOST
   */
  export interface AjouterFiltreUsingPOSTParams {

    /**
     * filtreDTO
     */
    filtreDTO: FiltreDTO;

    /**
     * forceAdd
     */
    forceAdd?: boolean;
  }

  /**
   * Parameters for listeArbitragesUsingPOST
   */
  export interface ListeArbitragesUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeArbitragesLegerUsingPOST
   */
  export interface ListeArbitragesLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for calendrierEnAttenteArbitragesUsingGET
   */
  export interface CalendrierEnAttenteArbitragesUsingGETParams {

    /**
     * mois
     */
    mois?: string;

    /**
     * annee
     */
    annee?: string;
  }

  /**
   * Parameters for listeDossierEnArbitragesUsingGET
   */
  export interface ListeDossierEnArbitragesUsingGETParams {

    /**
     * numero de Page
     */
    numeroPage?: number;

    /**
     * date arbitrage
     */
    date?: string;
  }

  /**
   * Parameters for genererDocumentUsingGET
   */
  export interface GenererDocumentUsingGETParams {

    /**
     * numéro de grief
     */
    numeroGrief?: string;

    /**
     * idGabarit
     */
    idGabarit?: string;
  }

  /**
   * Parameters for listeNouveauxUsingPOST
   */
  export interface ListeNouveauxUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeNouveauxLegerUsingPOST
   */
  export interface ListeNouveauxLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeOrganisationnelsUsingPOST
   */
  export interface ListeOrganisationnelsUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeOrganisationnelsLegerUsingPOST
   */
  export interface ListeOrganisationnelsLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listePersonnelsUsingPOST
   */
  export interface ListePersonnelsUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listePersonnelsLegerUsingPOST
   */
  export interface ListePersonnelsLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for griefArbitragesBackUsingGET
   */
  export interface GriefArbitragesBackUsingGETParams {

    /**
     * toYear
     */
    toYear?: string;

    /**
     * toMonth
     */
    toMonth?: string;

    /**
     * fromYear
     */
    fromYear?: string;

    /**
     * fromMonth
     */
    fromMonth?: string;

    /**
     * format
     */
    format?: string;
  }

  /**
   * Parameters for griefArbitragesFrontUsingGET
   */
  export interface GriefArbitragesFrontUsingGETParams {

    /**
     * toYear
     */
    toYear?: string;

    /**
     * toMonth
     */
    toMonth?: string;

    /**
     * fromYear
     */
    fromYear?: string;

    /**
     * fromMonth
     */
    fromMonth?: string;

    /**
     * format
     */
    format?: string;
  }

  /**
   * Parameters for rapportExcelUtilisateurUsingPOST
   */
  export interface RapportExcelUtilisateurUsingPOSTParams {

    /**
     * categorie
     */
    categorie: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for rapportExcelUtilisateurLocalBisUsingPOST
   */
  export interface RapportExcelUtilisateurLocalBisUsingPOSTParams {

    /**
     * categorie
     */
    categorie: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeTousUsingPOST
   */
  export interface ListeTousUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeTousLegerUsingPOST
   */
  export interface ListeTousLegerUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for listeTousLegerNouveauxInclusUsingPOST
   */
  export interface ListeTousLegerNouveauxInclusUsingPOSTParams {

    /**
     * numeroPage
     */
    numeroPage: number;

    /**
     * liste de critere
     */
    rechercheDossierLegerDto?: RechercheDossierLegerDto;
  }

  /**
   * Parameters for afficherPieceJointeFromGriefUsingGET
   */
  export interface AfficherPieceJointeFromGriefUsingGETParams {

    /**
     * idPieceJointe
     */
    idPieceJointe: string;

    /**
     * idGrief
     */
    idGrief: string;
  }

  /**
   * Parameters for afficherPieceJointeFromGriefDecomUsingGET
   */
  export interface AfficherPieceJointeFromGriefDecomUsingGETParams {

    /**
     * keyName
     */
    keyName: string;

    /**
     * idGrief
     */
    idGrief: string;
  }

  /**
   * Parameters for ajouterPieceJointeUsingPOST
   */
  export interface AjouterPieceJointeUsingPOSTParams {

    /**
     * idGrief
     */
    idGrief: string;

    /**
     * file
     */
    file: Blob;

    /**
     * pieceJointeDTO
     */
    pieceJointeDTO?: string;

    /**
     * forceAdd
     */
    forceAdd?: boolean;
  }

  /**
   * Parameters for deletePieceJointeUsingDELETE
   */
  export interface DeletePieceJointeUsingDELETEParams {

    /**
     * keyName
     */
    keyName: string;

    /**
     * idPieceJointe
     */
    idPieceJointe: string;

    /**
     * idGrief
     */
    idGrief: string;
  }
}

export { GriefControllerService }
