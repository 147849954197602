import { NgModule } from '@angular/core';
import { CommonConfigurationService } from '@ngxhq/config';
import { ThemeHqCommonUiSupportModule, ThemeHqCustomComponentModule } from '@ngxhq/theme-ngxhq';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfiguration } from './config/app-config.model';
import { SharedPrimeNgModule } from './shared-primeng.module';
import { TableComponent } from './components/table/table.component';
import { ConfirmationService, MessageService, FilterService } from 'primeng/api';
import { HasRoleDirective } from '../directives/has-role.directive';
import { HQFormLayoutModule } from './components/layout/hq-form-layout-module';
import { SortPipe } from './pipes/sort.pipe';
import { TypeGrievancePipe } from './pipes/type-grievance.pipe';
import { TableAdvancedSearchComponent } from './components/table-advanced-search/table-advanced-search.component';

@NgModule({
    imports: [
        CommonModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        FormsModule,
        SharedPrimeNgModule
    ],
    exports: [
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        FormsModule,
        ReactiveFormsModule,
        TableComponent,
        TableAdvancedSearchComponent,
        HasRoleDirective,
        HQFormLayoutModule,
        SortPipe,
        TypeGrievancePipe
    ],
    declarations: [
        TableComponent,
        HasRoleDirective,
        SortPipe,
        TableAdvancedSearchComponent,
        TypeGrievancePipe
    ],
    providers: [
        MessageService,
        FilterService,
        ConfirmationService,
        {
            provide: AppConfiguration,
            useFactory: (commonConfigurationService: CommonConfigurationService<AppConfiguration>) => commonConfigurationService.getConfiguration(AppConfiguration),
            deps: [CommonConfigurationService]
        }
    ],
})
export class SharedModule {
}
