/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { ActuatorService } from './services/actuator.service';
import { BatchService } from './services/batch.service';
import { GriefControllerService } from './services/grief-controller.service';
import { InfoService } from './services/info.service';
import { ListeService } from './services/liste.service';
import { PilotageControllerService } from './services/pilotage-controller.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    ActuatorService,
    BatchService,
    GriefControllerService,
    InfoService,
    ListeService,
    PilotageControllerService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
