<p-dialog class="dialog-home" header="Préparation de l'application" [(visible)]="visibleLoading" [modal]="true"
    [draggable]="false" [resizable]="false" [closable]="false" [style]="{width: '35vw', height: '30vh'}"
    [style.display]="!visibleLoading ? 'none' : ''">
    <div class="flex flex-column justify-content-center align-items-center h-full">
        <p-progressSpinner class="mt-2"></p-progressSpinner>
        <span>Veuillez patienter pendant que le système prépare l'application</span>
    </div>
</p-dialog>


<div id="home">
    <h1 class="user-fullname">
        Bonjour {{ userFullname }}
    </h1>
    <div class="grid p-card-home">
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Mes griefs ({{infoApp?.nbMesGriefs}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'mes-griefs']" *appHasRole="['user']">
            <ng-template pTemplate="header">
                <i class="fas fa-address-card"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Saisir un grief"
            [routerLink]="['/griefs', 'saisir-un-grief']" *appHasRole="['admin', 'admin-ti', 'syndicate']">
            <ng-template pTemplate="header">
                <i class="fas fa-plus-circle"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Gestion des documents"
            [routerLink]="['/document-management']" *appHasRole="['admin', 'admin-ti', 'user', 'manager']">
            <ng-template pTemplate="header">
                <i class="fas fa-book"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action"
            header="Les griefs de mon/mes unité(s) ({{infoApp?.nbGriefsGestionnaire}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'griefs-de-mon-mes-unites']" *appHasRole="['manager']">
            <ng-template pTemplate="header">
                <i class="fas fa-users"></i>
            </ng-template>
        </p-card>
    </div>
    <div class="grid p-card-home mt">
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Nouveaux griefs ({{infoApp?.nbGriefsNouveaux}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'nouveaux-griefs']" *appHasRole="['admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="fas fa-shopping-cart"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action"
            header="Prochainement en arbitrage ({{infoApp?.nbGriefsProchArbitrage}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'prochainement-en-arbitrage']"
            *appHasRole="['user', 'admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="fas fa-gavel"></i>
            </ng-template>
        </p-card>
    </div>
    <div class="grid p-card-home">
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Griefs actifs ({{infoApp?.nbGriefsActifs}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'griefs-actifs']" *appHasRole="['user', 'admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="fas fa-folder-open"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Tous les griefs ({{infoApp?.nbGriefsTous}})"
            [routerLink]="['/griefs', 'liste-des-griefs', 'tous-les-griefs']"
            *appHasRole="['user', 'admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="appicons ic-list"></i>
            </ng-template>
        </p-card>
    </div>
    <div class="grid p-card-home">
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Recherche avancée"
            [routerLink]="['/griefs', 'liste-des-griefs', 'recherche-avancee']"
            *appHasRole="['user', 'admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="fas fa-search-plus"></i>
            </ng-template>
        </p-card>
    </div>
    <div class="grid p-card-home mt">
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Tableaux de bord" [routerLink]="['/dashboard']"
            *appHasRole="['admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="fas fa-clipboard"></i>
            </ng-template>
        </p-card>
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Pilotage du système"
            [routerLink]="['/pilotage']" *appHasRole="['admin', 'admin-ti']">
            <ng-template pTemplate="header">
                <i class="appicons appicons ic-cog"></i>
            </ng-template>
        </p-card>
    </div>
    <div class="grid p-card-home mt">
        <!-- CHANGE ROLE FOR ONLY ResponsableRH, Admin & Admin-TI -->
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Gestion des unités structurelles non existante"
            [routerLink]="['/non-existing-structural-unit']" *appHasRole="['admin', 'admin-ti', 'user']">
            <ng-template pTemplate="header">
                <i class="fas fa-clipboard-list"></i>
            </ng-template>
        </p-card>

        <!-- CHANGE ROLE FOR ONLY USER -->
        <p-card class="col-12 md:col-6 lg:col-4 call-to-action" header="Gestion du responsable RH"
            [routerLink]="['/hr-management']" *appHasRole="['admin']">
            <ng-template pTemplate="header">
                <i class="fas fa-users-cog"></i>
            </ng-template>
        </p-card>
    </div>
</div>
