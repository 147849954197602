<hq-header image="assets/theme-ngxhq/images/logo-hydro-quebec-small.png"
    headerTitle="Gestion des griefs {{ appInfo?.environment !== 'prod' && appInfo?.environment !== 'nprod' ? '(' + (appInfo?.environment | uppercase) + ')' : '' }}"
    (expandedChange)='expanded=$event'>
    <hq-header-menu>
        <hq-header-menu-section>
            <hq-header-menu-item-group [icon]="user ? 'appicons ic-user' : 'appicons ic-sign-in-alt'" label="Profile">
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <div class='hq-header-menu-profile'>
                            <div class='hq-header-menu-profile__image'>
                                <img *ngIf="user?.profileImage" [src]='user?.profileImage' alt='' />
                                <div class='hq-header-menu-profile__initials' *ngIf="!user?.profileImage">
                                    {{user?.initials | uppercase}}
                                </div>
                            </div>
                            <div class='hq-header-menu-profile__description'>
                                <div class='hq-header-menu-profile__user-fullname'>{{user?.fullname}}</div>
                            </div>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="!user">
                    <hq-header-menu-item-container>
                        <button pButton type="button" label="Se connecter" icon='appicons ic-sign-in-alt'
                            [hqClickCommand]="login">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <button pButton type="button" label="Se déconnecter" icon='appicons ic-sign-out-alt'
                            [hqClickCommand]="logout">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

            </hq-header-menu-item-group>
            <!-- App info -->
            <hq-header-menu-item-group icon="appicons ic-question" label="Info">
                <hq-header-menu-section>
                    <hq-header-menu-item-container>
                        <div class="app-info">
                            <dl class="app-info__section">
                                <dt class="app-info__title">Version:</dt>
                                <dd class="app-info__description">{{ appInfo?.version }}</dd>
                            </dl>
                            <dl class="app-info__section">
                                <dt class="app-info__title">Date de livraison:</dt>
                                <dd class="app-info__description">{{ appInfo?.date }}</dd>
                            </dl>
                            <dl class="app-info__section"
                                *ngIf="appInfo?.environment !== 'prod' && appInfo?.environment !== 'nprod'">
                                <dt class="app-info__title">Environnement:</dt>
                                <dd class="app-info__description">{{ appInfo?.environment | uppercase }}</dd>
                            </dl>
                            <dl class="app-info__section">
                                <dt class="app-info__title">Privilèges:</dt>
                                <dd class="app-info__description">{{ appInfo?.roles }}</dd>
                            </dl>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>
            </hq-header-menu-item-group>
        </hq-header-menu-section>
    </hq-header-menu>
</hq-header>
