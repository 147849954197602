<hq-side-bar id="main-menu" (expandedChange)='expanded=$event'>
    <hq-menu-item label='Accueil' icon='appicons ic-home' [routerLink]="['/accueil']">
    </hq-menu-item>

    <hq-menu-item-group label='Gestion des griefs' id="gestion-griefs" icon='appicons ic-folder-open'
        [expanded]='(href === "griefs" || href === "document-management" || href === "non-existing-structural-unit" || href === "hr-management" || href === "document-management")'>
        <hq-menu-item label='Mes griefs ({{(infoApp$ | async)?.nbMesGriefs}})' *appHasRole="['user']"
            icon='fas fa-address-card' [routerLink]="['/griefs', 'liste-des-griefs', 'mes-griefs']">
        </hq-menu-item>
        <hq-menu-item label='Saisir un grief' *appHasRole="['admin', 'admin-ti', 'syndicate']" icon='appicons ic-edit'
            [routerLink]="['/griefs', 'saisir-un-grief']">
        </hq-menu-item>
        <hq-menu-item label='Nouveaux griefs ({{(infoApp$ | async)?.nbGriefsNouveaux}})'
            *appHasRole="['admin', 'admin-ti']" icon='fas fa-shopping-cart'
            [routerLink]="['/griefs', 'liste-des-griefs', 'nouveaux-griefs']">
        </hq-menu-item>
        <hq-menu-item label='Prochainement en arbitrage ({{(infoApp$ | async)?.nbGriefsProchArbitrage}})'
            *appHasRole="['user', 'admin', 'admin-ti']" icon='fas fa-gavel'
            [routerLink]="['/griefs', 'liste-des-griefs', 'prochainement-en-arbitrage']">
        </hq-menu-item>
        <hq-menu-item label='Les griefs de mon/mes unité(s) ({{(infoApp$ | async)?.nbGriefsGestionnaire}})'
            *appHasRole="['manager']" icon='fas fa-users'
            [routerLink]="['/griefs', 'liste-des-griefs', 'griefs-de-mon-mes-unites']">
        </hq-menu-item>
        <hq-menu-item label='Griefs actifs ({{(infoApp$ | async)?.nbGriefsActifs}})'
            *appHasRole="['user', 'admin', 'admin-ti']" icon='fas fa-folder-open'
            [routerLink]="['/griefs', 'liste-des-griefs', 'griefs-actifs']">
        </hq-menu-item>
        <hq-menu-item label='Tous les griefs ({{(infoApp$ | async)?.nbGriefsTous}})' icon='appicons ic-list'
            *appHasRole="['user', 'admin', 'admin-ti']"
            [routerLink]="['/griefs', 'liste-des-griefs', 'tous-les-griefs']">
        </hq-menu-item>
        <hq-menu-item label='Recherche avancée' icon='fas fa-search-plus' *appHasRole="['user', 'admin', 'admin-ti']"
            [routerLink]="['/griefs', 'liste-des-griefs', 'recherche-avancee']">
        </hq-menu-item>
        <hq-menu-item label='Gestion des documents' icon='fas fa-book'
            *appHasRole="['admin', 'admin-ti', 'user', 'manager']" [routerLink]="['/document-management']">
        </hq-menu-item>
        <hq-menu-item [ngClass]="(href === 'non-existing-structural-unit') ? 'actived' : ''"
            label='Gestion des unités structurelles non existante' *appHasRole="['admin', 'admin-ti', 'user']"
            icon='fas fa-clipboard-list' [routerLink]="['/non-existing-structural-unit']">
        </hq-menu-item>
        <hq-menu-item *appHasRole="['admin']" label='Gestion du responsable RH' icon='fas fa-users-cog'
            [routerLink]="['/hr-management']">
        </hq-menu-item>
        <!-- CHANGE ROLE FOR ONLY USER -->
        <hq-menu-item label='Tableaux de bord' icon='fas fa-clipboard' *appHasRole="['admin', 'admin-ti']"
            [routerLink]="['/dashboard']">
        </hq-menu-item>
    </hq-menu-item-group>

    <!-- <hq-menu-item-group label='Gestion de griefs' id="gestion-griefs">
        <hq-menu-item [ngClass]="(href === 'non-existing-structural-unit') ? 'actived' : ''"
            *appHasRole="['manager', 'admin']" label='Gestion des unités structurelles non existante'
            icon='fas fa-clipboard-list' [routerLink]="['/non-existing-structural-unit']">
        </hq-menu-item>
        <hq-menu-item *appHasRole="['user', 'manager', 'admin']" label='Gestion du responsable RH'
            icon='fas fa-users-cog' [routerLink]="['/hr-management']">
        </hq-menu-item>
    </hq-menu-item-group>-->
    <hq-menu-item-group *appHasRole="['admin', 'admin-ti']" label='Pilotage' icon='appicons ic-cog'
        [expanded]='href === "pilotage"'>
        <hq-menu-item label='Motifs' icon='fas fa-project-diagram' [routerLink]="['/pilotage', 'motifs']">
        </hq-menu-item>
        <hq-menu-item label='Sous-motifs' icon='fas fa-sitemap' [routerLink]="['/pilotage', 'sous-motifs']">
        </hq-menu-item>
        <hq-menu-item label='Arbitres' icon='fas fa-gavel' [routerLink]="['/pilotage', 'arbitres']">
        </hq-menu-item>
        <hq-menu-item label='Procureurs syndicaux' icon='fas fa-balance-scale'
            [routerLink]="['/pilotage', 'procureurs-syndicaux']">
        </hq-menu-item>
        <hq-menu-item label='Procureurs patronaux' icon='fas fa-chalkboard-teacher'
            [routerLink]="['/pilotage', 'procureurs-patronaux']">
        </hq-menu-item>
        <hq-menu-item label='Responsables RH' icon='fas fa-users' [routerLink]="['/pilotage', 'responsable-rh']">
        </hq-menu-item>
        <hq-menu-item label='Types de pièces jointes' icon='fas fa-copy'
            [routerLink]="['/pilotage', 'type-pieces-jointes']">
        </hq-menu-item>
        <hq-menu-item label="Paramètres d'application" icon='fas fa-cog'
            [routerLink]="['/pilotage', 'parametres-application']">
        </hq-menu-item>
        <hq-menu-item label='Utilisateurs' icon='appicons ic-user' [routerLink]="['/pilotage', 'utilisateurs']">
        </hq-menu-item>
    </hq-menu-item-group>

    <hq-menu-item *ngIf="env === 'dev' || env === 'qa'" label='Swagger' icon='fas fa-bullseye'
        (click)="swaggerExternalLink()">
    </hq-menu-item>


</hq-side-bar>
