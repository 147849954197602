import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { DoInit, ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks, SaveCommand } from '@ngxhq/common-ui';
import { OpenIdService, OpenIdUserInfo, AuthentificationState } from '@ngxhq/security';
import { from } from 'rxjs';
import { switchMap, takeUntil, take } from 'rxjs/operators';
import { HqHeaderComponent } from '@ngxhq/theme-ngxhq';
import { SecurityService } from '../../services/security.service';
import { AppInfo } from '../../models/app-info.interface';
const pjson = require('../../../../package.json');
interface UserViewModel {
    fullname: string;
    initials: string;
    profileImage?: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HeaderComponent extends ComponentBase implements DoInit {
    public appInfo: AppInfo = {
        environment: pjson.environment,
        date: pjson.date,
        version: pjson.version,
        roles: ''
    };
    @ViewChild(HqHeaderComponent)
    private header: HqHeaderComponent;

    user: UserViewModel | undefined;
    @Output() expandedChange = new EventEmitter<boolean>();

    @Input()
    set expanded(value: boolean) {
        if (this.header && this.header.expanded !== value) {
            this.header.expanded = value;
        }
        this.expandedChange.emit(value);
    }

    constructor(
        private openIdService: OpenIdService,
        private _securityService: SecurityService
    ) {
        super();
    }

    doInit() {
        this.openIdService.status
            .pipe(
                switchMap(x => from(this.mapStatusToViewModel(x))),
                takeUntil(this.destroyed),
            )
            .subscribe({
                next: user => {
                    this.user = user;
                },
                error: (err: any) => {
                    console.log('HeaderComponent - openIdService status subsription in error.', err);
                    if ((typeof err !== 'object') || err.message.indexOf('Missing OpenID configuration') === -1) {
                        throw err;
                    }
                }
            });

        this._securityService.initRoles()
            .pipe(take(1))
            .subscribe({
                next: (roles: string[] | undefined) => {
                    console.log('roles: ', roles);
                    if (roles && Array.isArray(roles)) {
                        this.appInfo.roles = roles.join(', ');
                    }
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
    }

    private async mapStatusToViewModel(x: AuthentificationState): Promise<UserViewModel | undefined> {
        return x.isAuthenticated
            ? this.mapToViewModel(await this.openIdService.getUserInfo())
            : undefined;
    }

    private mapToViewModel(userInfo: OpenIdUserInfo | undefined): UserViewModel | undefined {
        if (!userInfo) {
            return undefined;
        }
        return {
            fullname: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
            initials: `${userInfo.firstName[0] || ''}${userInfo.lastName[0] || ''}`,
        };
    }

    @SaveCommand()
    async login() {
        await this.openIdService.login('');
    }

    @SaveCommand()
    async logout() {
        await this.openIdService.logout();

        this._removeStorageInfosUser();
    }

    /**
     * @returns void
     */
    private _removeStorageInfosUser(): void {
        sessionStorage.removeItem('infosUser');
    }
}
