/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ModelAndView } from '../models/model-and-view';

/**
 * Informations sur la santé de l'application
 */
@Injectable({
  providedIn: 'root',
})
class ActuatorService extends __BaseService {
  static readonly redirectWithUsingForwardPrefixToHealthUsingGETPath = '/actuator/health';
  static readonly redirectWithUsingForwardPrefixToInfoUsingGETPath = '/actuator/info';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Informations sur la santé de l'application
   * @return OK
   */
  redirectWithUsingForwardPrefixToHealthUsingGETResponse(): __Observable<__StrictHttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/actuator/health`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelAndView>;
      })
    );
  }
  /**
   * Informations sur la santé de l'application
   * @return OK
   */
  redirectWithUsingForwardPrefixToHealthUsingGET(): __Observable<ModelAndView> {
    return this.redirectWithUsingForwardPrefixToHealthUsingGETResponse().pipe(
      __map(_r => _r.body as ModelAndView)
    );
  }

  /**
   * Informations personnalisées de l'application
   * @return OK
   */
  redirectWithUsingForwardPrefixToInfoUsingGETResponse(): __Observable<__StrictHttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/actuator/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelAndView>;
      })
    );
  }
  /**
   * Informations personnalisées de l'application
   * @return OK
   */
  redirectWithUsingForwardPrefixToInfoUsingGET(): __Observable<ModelAndView> {
    return this.redirectWithUsingForwardPrefixToInfoUsingGETResponse().pipe(
      __map(_r => _r.body as ModelAndView)
    );
  }
}

module ActuatorService {
}

export { ActuatorService }
