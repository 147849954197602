import { Translation } from 'primeng/api';

export const CALENDAR_FR: Translation = {
    cancel: 'Fermer',
    today: 'Aujourd\'hui',
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
        'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    weekHeader: 'Sem.',
    clear: 'Réinitialiser',
    dateIs: 'La date est',
    dateIsNot: 'La date n\'est pas',
    dateBefore: 'La date est avant',
    dateAfter: 'La date est après',
    noFilter: 'Pas de filtre'
};
