import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HasPermissionForAppGuard implements CanActivate {
    constructor(
        private _securityService: SecurityService,
        private _router: Router
    ) { }

    /**
     * @returns Observable
     */
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const hasRole: Observable<boolean> = route.params.context ?
            this._securityService.hasRole(this._validateRoleFromContext(route.params.context)) :
            this._securityService.hasRole(route.data.roles);
        return hasRole.pipe(
            tap(async (role: boolean) => {
                if (!role) {
                    await this._router.navigate(['erreur/http/401']);
                }
            })
        );
    }

    /**
     * @param  {string} context
     * @returns string
     */
    private _validateRoleFromContext(context: string): string[] {
        let roles: string[];

        switch (context) {
            case 'griefs-de-mon-mes-unites':
                roles = ['manager'];
                break;
            case 'mes-griefs':
                roles = ['user'];
                break;
            default:
                roles = ['user', 'admin', 'admin-ti'];
                break;
        }
        return roles;
    }

}
