/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Batch Controller
 */
@Injectable({
  providedIn: 'root',
})
class BatchService extends __BaseService {
  static readonly conclusionCorrectionUsingGETPath = '/api/v1/batch/conclusionCorrection';
  static readonly conversionAutoriseUsingGETPath = '/api/v1/batch/conversionAutorise';
  static readonly conversionAutorisePhase4UsingGETPath = '/api/v1/batch/conversionAutorisePhase4';
  static readonly conversionBlockUsingGETPath = '/api/v1/batch/conversionBlock';
  static readonly conversionBlockPhase4UsingGETPath = '/api/v1/batch/conversionBlockPhase4';
  static readonly conversionDeleteListGriefsUsingPOSTPath = '/api/v1/batch/conversionDeleteListGriefs';
  static readonly conversionDeleteUsingGETPath = '/api/v1/batch/conversionDeleteMinMax';
  static readonly conversionPhase1UsingGETPath = '/api/v1/batch/conversionPhase1';
  static readonly conversionPhase1ListGriefsUsingPOSTPath = '/api/v1/batch/conversionPhase1ListGriefs';
  static readonly conversionPhase2UsingGETPath = '/api/v1/batch/conversionPhase2';
  static readonly conversionStateUsingGETPath = '/api/v1/batch/conversionState';
  static readonly loadGenDossiers2UsingGETPath = '/api/v1/batch/loadGenDossiers2';
  static readonly loadGenDossiers2CheckUsingGETPath = '/api/v1/batch/loadGenDossiers2Check';
  static readonly loadGenEmployesUsingGETPath = '/api/v1/batch/loadGenEmployes';
  static readonly loadGenUnitesStructurellesUsingGETPath = '/api/v1/batch/loadGenUnitesStructurelles';
  static readonly loadGenUtilisateursUsingGETPath = '/api/v1/batch/loadGenUtilisateurs';
  static readonly loadResponsablesRHUsingGETPath = '/api/v1/batch/loadResponsablesRH';
  static readonly modifierStatutUsingGETPath = '/api/v1/batch/miseAJourStatut';
  static readonly miseAJourUniteStructurelleUsingGETPath = '/api/v1/batch/miseAJourUniteStructurelle';
  static readonly updateDossiersRequeantUsingGETPath = '/api/v1/batch/updateDossiersRequeant';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * conclusionCorrection
   * @return OK
   */
  conclusionCorrectionUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conclusionCorrection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * conclusionCorrection
   * @return OK
   */
  conclusionCorrectionUsingGET(): __Observable<string> {
    return this.conclusionCorrectionUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Autorise conversion
   * @return OK
   */
  conversionAutoriseUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionAutorise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Autorise conversion
   * @return OK
   */
  conversionAutoriseUsingGET(): __Observable<string> {
    return this.conversionAutoriseUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Autorise conversion Phase4
   * @return OK
   */
  conversionAutorisePhase4UsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionAutorisePhase4`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Autorise conversion Phase4
   * @return OK
   */
  conversionAutorisePhase4UsingGET(): __Observable<string> {
    return this.conversionAutorisePhase4UsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Block conversion
   * @return OK
   */
  conversionBlockUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionBlock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Block conversion
   * @return OK
   */
  conversionBlockUsingGET(): __Observable<string> {
    return this.conversionBlockUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Block conversionPhase4
   * @return OK
   */
  conversionBlockPhase4UsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionBlockPhase4`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Block conversionPhase4
   * @return OK
   */
  conversionBlockPhase4UsingGET(): __Observable<string> {
    return this.conversionBlockPhase4UsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * conversionDeleteListGriefs
   * @param griefList griefList
   * @return OK
   */
  conversionDeleteListGriefsUsingPOSTResponse(griefList: Array<string>): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = griefList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/batch/conversionDeleteListGriefs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * conversionDeleteListGriefs
   * @param griefList griefList
   * @return OK
   */
  conversionDeleteListGriefsUsingPOST(griefList: Array<string>): __Observable<string> {
    return this.conversionDeleteListGriefsUsingPOSTResponse(griefList).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionDeleteUsingGETParams` containing the following parameters:
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionDeleteUsingGETResponse(params: BatchService.ConversionDeleteUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.min != null) __params = __params.set('min', params.min.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionDeleteMinMax`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionDeleteUsingGETParams` containing the following parameters:
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionDeleteUsingGET(params: BatchService.ConversionDeleteUsingGETParams): __Observable<string> {
    return this.conversionDeleteUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase1UsingGETParams` containing the following parameters:
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase1UsingGETResponse(params: BatchService.ConversionPhase1UsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.min != null) __params = __params.set('min', params.min.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionPhase1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase1UsingGETParams` containing the following parameters:
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase1UsingGET(params: BatchService.ConversionPhase1UsingGETParams): __Observable<string> {
    return this.conversionPhase1UsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase1ListGriefsUsingPOSTParams` containing the following parameters:
   *
   * - `griefList`: griefList
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase1ListGriefsUsingPOSTResponse(params: BatchService.ConversionPhase1ListGriefsUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.griefList;
    if (params.min != null) __params = __params.set('min', params.min.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/batch/conversionPhase1ListGriefs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase1ListGriefsUsingPOSTParams` containing the following parameters:
   *
   * - `griefList`: griefList
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase1ListGriefsUsingPOST(params: BatchService.ConversionPhase1ListGriefsUsingPOSTParams): __Observable<string> {
    return this.conversionPhase1ListGriefsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase2UsingGETParams` containing the following parameters:
   *
   * - `phase`: phase
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase2UsingGETResponse(params: BatchService.ConversionPhase2UsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.phase != null) __params = __params.set('phase', params.phase.toString());
    if (params.min != null) __params = __params.set('min', params.min.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionPhase2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Conversion de donnees
   * @param params The `BatchService.ConversionPhase2UsingGETParams` containing the following parameters:
   *
   * - `phase`: phase
   *
   * - `min`: min
   *
   * - `max`: max
   *
   * @return OK
   */
  conversionPhase2UsingGET(params: BatchService.ConversionPhase2UsingGETParams): __Observable<string> {
    return this.conversionPhase2UsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * State conversion
   * @return OK
   */
  conversionStateUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/conversionState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * State conversion
   * @return OK
   */
  conversionStateUsingGET(): __Observable<string> {
    return this.conversionStateUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadGenDossiers2
   * @return OK
   */
  loadGenDossiers2UsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadGenDossiers2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadGenDossiers2
   * @return OK
   */
  loadGenDossiers2UsingGET(): __Observable<string> {
    return this.loadGenDossiers2UsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadGenDossiers2check
   * @return OK
   */
  loadGenDossiers2CheckUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadGenDossiers2Check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadGenDossiers2check
   * @return OK
   */
  loadGenDossiers2CheckUsingGET(): __Observable<string> {
    return this.loadGenDossiers2CheckUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadGenEmployes
   * @return OK
   */
  loadGenEmployesUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadGenEmployes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadGenEmployes
   * @return OK
   */
  loadGenEmployesUsingGET(): __Observable<string> {
    return this.loadGenEmployesUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadGenUnitesStructurelles
   * @return OK
   */
  loadGenUnitesStructurellesUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadGenUnitesStructurelles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadGenUnitesStructurelles
   * @return OK
   */
  loadGenUnitesStructurellesUsingGET(): __Observable<string> {
    return this.loadGenUnitesStructurellesUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadGenUtilisateurs
   * @return OK
   */
  loadGenUtilisateursUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadGenUtilisateurs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadGenUtilisateurs
   * @return OK
   */
  loadGenUtilisateursUsingGET(): __Observable<string> {
    return this.loadGenUtilisateursUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * loadResponsablesRH
   * @return OK
   */
  loadResponsablesRHUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/loadResponsablesRH`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * loadResponsablesRH
   * @return OK
   */
  loadResponsablesRHUsingGET(): __Observable<string> {
    return this.loadResponsablesRHUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Modifier Statut quand la date est plus grande
   * @return OK
   */
  modifierStatutUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/miseAJourStatut`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Modifier Statut quand la date est plus grande
   * @return OK
   */
  modifierStatutUsingGET(): __Observable<string> {
    return this.modifierStatutUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Mise a jour UniteStructurelle
   * @return OK
   */
  miseAJourUniteStructurelleUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/miseAJourUniteStructurelle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Mise a jour UniteStructurelle
   * @return OK
   */
  miseAJourUniteStructurelleUsingGET(): __Observable<string> {
    return this.miseAJourUniteStructurelleUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Conversion de donnees
   * @return OK
   */
  updateDossiersRequeantUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/batch/updateDossiersRequeant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Conversion de donnees
   * @return OK
   */
  updateDossiersRequeantUsingGET(): __Observable<string> {
    return this.updateDossiersRequeantUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BatchService {

  /**
   * Parameters for conversionDeleteUsingGET
   */
  export interface ConversionDeleteUsingGETParams {

    /**
     * min
     */
    min?: number;

    /**
     * max
     */
    max?: number;
  }

  /**
   * Parameters for conversionPhase1UsingGET
   */
  export interface ConversionPhase1UsingGETParams {

    /**
     * min
     */
    min?: number;

    /**
     * max
     */
    max?: number;
  }

  /**
   * Parameters for conversionPhase1ListGriefsUsingPOST
   */
  export interface ConversionPhase1ListGriefsUsingPOSTParams {

    /**
     * griefList
     */
    griefList: Array<string>;

    /**
     * min
     */
    min?: number;

    /**
     * max
     */
    max?: number;
  }

  /**
   * Parameters for conversionPhase2UsingGET
   */
  export interface ConversionPhase2UsingGETParams {

    /**
     * phase
     */
    phase?: number;

    /**
     * min
     */
    min?: number;

    /**
     * max
     */
    max?: number;
  }
}

export { BatchService }
