import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typeGrievance'
})
export class TypeGrievancePipe implements PipeTransform {

    /**
     * Commentaire pour transform
     * Changer le code de la valeur pour la description
     * @param  {string} value
     * @returns string
     */
    transform(value: string): string {
        switch (value) {
            case 'IND':
                return 'Individuel';
            case 'PAT':
                return 'Patronal';
            case 'GEN':
                return 'Portée générale';
            case 'COL':
                return 'Collectif';
            default:
                return '';
        }
    }

}
