import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {

    /**
     * Commentaire pour transform
     * Organiser la liste selon la direction désirée
     * @param  {any} value
     * @param  {string} property
     * @param  {'asc'|'desc'} direction
     * @returns any
     */
    transform(value: any, property: string, direction: 'asc' | 'desc'): any {

        if (value != null && !property.includes('.')) {
            value.sort((a: any, b: any) => {
                const aValue: string = a[property];
                const bValue: string = b[property];

                if (aValue != null && bValue != null) {
                    if (direction === 'asc') {
                        return aValue.localeCompare(bValue);
                    } else {
                        return bValue.localeCompare(aValue);
                    }
                } else {
                    return value;
                }
            });
            return value;

        } else if (value != null && property.includes('.')) {
            const parent: string = (property.split('.'))[0];
            const child: string = (property.split('.'))[1];

            value.sort((a: any, b: any) => {
                const aValue: string = a[parent][child];
                const bValue: string = b[parent][child];

                if (aValue != null && bValue != null) {
                    if (direction === 'asc') {
                        return aValue.localeCompare(bValue);
                    } else {
                        return bValue.localeCompare(aValue);
                    }
                } else {
                    return value;
                }
            });
            return value;

        } else {
            return [];
        }
    }
}
